import React from "react";
import styled from "styled-components";
import { white_close ,x_btn,fb_btn,gmail_btn,insta_btn,slack_btn,whatsapp_btn,ref_copy } from "../../assets/images";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const showToast1 = () => {
    // console.log("Showing toast");
    toast(<CustomToast1 message={"Link Copied Successfully"} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToast1 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };


const ShareLinkPopup = ({ onClose, url }) => {
  
  const share_url = url;
  const share_url1 = encodeURIComponent(url);

    const socialMediaData = [
        { src: x_btn, alt: "x", href: `https://twitter.com/intent/tweet?url=${share_url1}` },  
        { src: fb_btn, alt: "facebook", href: `https://www.facebook.com/sharer/sharer.php?u=${share_url1}` },
        { src: gmail_btn, alt: "gmail", href: `mailto:?subject=Check this out&body=${share_url1}` },
        { src: whatsapp_btn, alt: "Whatsapp", href: `https://api.whatsapp.com/send?text=${share_url1}` }, 
        // { src: insta_btn, alt: "Instagram", href: `https://www.instagram.com/` }
        // { src: slack_btn, alt: "Slack", href: "" },  
      ];
      
      

     

      const handleCopy = () => {
        navigator.clipboard.writeText(share_url).then(() => {
          
          showToast1();
        });
      };

  return (
    <PopupContainer>
      <CloseIcon loading="lazy" onClick={onClose} src={white_close} alt="Close popup" />
      <PopupContent>
        <PopupTitle>Share a link</PopupTitle>

        <IconsContainer>
      {socialMediaData.map((icon, index) => (
        <SocialIcon key={index} loading="lazy" src={icon.src} alt={icon.alt}  onClick={() => window.open(icon.href, '_blank')} />
      ))}
    </IconsContainer>

       <ButtonContainer>
      <CopyButton>
        <LinkText>{share_url}</LinkText>
        <CopyWrapper onClick={handleCopy} >
          <CopyIcon loading="lazy" src={ref_copy} alt="Copy icon" />
          <CopyText>Copy</CopyText>
        </CopyWrapper>
      </CopyButton>
    </ButtonContainer>

      </PopupContent>
    </PopupContainer>
  );
};


const ButtonContainer = styled.div`
  display: flex;
  margin-top: 28px;
  width: 100%;
  flex-direction: column;
  color: #ffffff;
  text-align: center;
  justify-content: flex-start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CopyButton = styled.button`
  border-radius: 8px;
  background-color: #3a3b3b;
  display: flex;
  min-height: 56px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 18px 24px 18px 12px;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const LinkText = styled.span`
  letter-spacing: 0.9px;
  font: 500 15px/1 Poppins, sans-serif;
  color: #ffffff;
  width: 425px;          /* Set the fixed width */
  overflow: hidden;      /* Hide overflowing content */
  text-overflow: ellipsis; /* Show ellipsis when text overflows */
  white-space: nowrap;   /* Prevent text from wrapping to the next line */
`;


const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  font: 700 14px Inter, sans-serif;
`;


const CopyIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 20px;
`;

const CopyText = styled.span`
  color: #ffffff;
`;

const SocialIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 40px;
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
`;

const IconsContainer = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
  margin-top: 20px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const PopupContainer = styled.section`
  border-radius: 12px;
  background-color: #282828;
  position: relative;
  display: flex;
  width: 512px;
  height:200px;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  padding: 26px 16px;
   @media (max-width: 991px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const CloseIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 25px;
  position: absolute;
  z-index: 1;
  right: 37px;
  top: 35px;
  height: 25px;
  cursor: pointer;
  @media (max-width: 991px) {
    right: 15px;
  }
`;

const PopupContent = styled.div`
  z-index: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PopupTitle = styled.div`
  color: #ffffff;
  letter-spacing: -0.2px;
  text-align: center;
  margin-top:10px;
  font: 600 20px/1.2 Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default ShareLinkPopup;