import React, { useState, useEffect, useRef } from "react";
import { styled, keyframes } from "styled-components";
import axios from "axios";
import config from "../../config";
import { getToken, getfirstname, getlastname } from "../utils";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { clearToken } from "../utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import error_icon from "../../assets/images/error_icon.svg";

const SharePage = ({ onClose }) => {
  const navigate = useNavigate();
  const token = getToken();
  const firstName = getfirstname();
  const lastName = getlastname();
  const [shareId, setShareId] = useState("");
  const [title, settitle] = useState("");
  const [img, setimg] = useState("");
  const [loading, setLoading] = useState(true);
  const [share_id, setshare_id] = useState("");

  const share_id_added1 = localStorage.getItem("share_id_added_after_login");
  const share_id_added = localStorage.getItem("share_id_added");
  // console.log("id"+id);
  // console.log("share_id_added1"+share_id_added1);

  useEffect(() => {
    if (share_id_added1) {
      setShareId(share_id_added1);
      get_share_details(share_id_added1);
      localStorage.removeItem("share_id_added_after_login");
     
    }
  }, [share_id_added1]);



  useEffect(() => {
    if (share_id_added) {
   
      setShareId(share_id_added);
      get_share_details(share_id_added);
     }
  }, [share_id_added]);

  const get_share_details = async (id) => {
    const encodedRequestId1 = encodeURIComponent(id);
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/share/info/${encodedRequestId1}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json(); // Parse the response to JSON
        const title1 = data.url_request.title;
        const img1 = data.url_request.thumbnail_url;
        const share_id1 = data.share.share_id;
       
        settitle(title1);
        setimg(img1);
        setshare_id(share_id1);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      setLoading(false);
    } finally {
      // setLoading(false);
    }
  };

  const add_to_music = async () => {
    const encodedRequestId1 = encodeURIComponent(share_id);
    setLoading(true);
    try {
      const response = await fetch(
        `${config.apiUrl}/url_requests/claim/${encodedRequestId1}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      // Check for non-200 status codes
      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 400 && errorData.message === "Subscription limit reached!") {
          localStorage.setItem("song_added_using_url", "Subscription limit reached!");
          navigate("/user");
          onClose();
        } else if (response.status === 401) {
          clearToken();
          navigate("/");
        }else if (response.status === 400 && errorData.message === "You already own this request!") {
          showToast1();
         
        }
        throw new Error(`Error: ${response.status}`);
      }
  
      // If response is OK (200)
      const data = await response.json();
      const requestId = data.request_id;
      localStorage.setItem("song_added_using_url", "song_added_using_url");
      navigate("/user");
      onClose();
    } catch (error) {
      console.error("Request failed", error);
    } finally {
      setLoading(false);
    }
  };


  const CustomToast1 = ({ data1 }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img
        src={error_icon}
        alt="check icon"
        style={{ marginRight: "8px", width: "24px", height: "24px" }}
      />
      <span style={{ flex: 1 }}>{data1}</span>
    </div>
  );

  const showToast1 = (data) => {
    toast(<CustomToast1 data1={"You have already own this shared Chords!"} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  

  //   const  imageUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/d46c3eed87be96c7783f487e5e99b1e40f565e8e5ee4cc75fe03927f7e1c94d7?placeholderIfAbsent=true&apiKey=2d7c5855cb2f42a1b4e6163799d67ecc";
  //   const overlayUrl="https://cdn.builder.io/api/v1/image/assets/TEMP/2be70ac1d7d61fa2af16f05036f0a0bfa3bb01cd4177ca429739fe641c21b315?placeholderIfAbsent=true&apiKey=2d7c5855cb2f42a1b4e6163799d67ecc";
  //   const title="Perfect Ed Sheeran";
  return (
    <PopupContainer>
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>
      <HeaderContainer>
        {/* <Greeting>Hey {firstName || lastName ? `${firstName} ${lastName}` : ''}</Greeting> */}
        <Greeting>Hey Jammer!</Greeting>
        <CloseButton onClick={onClose}>×</CloseButton>
        <Message>You have been invited to check out these cool chords!</Message>
      </HeaderContainer>

      <SongCardContainer>
        <AlbumCover2 loading="lazy" src={img} />
        <SongInfo2>
          <SongTitle2>{title}</SongTitle2>
        </SongInfo2>
      </SongCardContainer>

      <Button onClick={add_to_music}>Add to My Music</Button>
    </PopupContainer>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
  @media (max-width: 991px) {
    right: 15px;
  }
`;

const SongCardContainer = styled.article`
  margin-top: 10px;
 
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 40%; // Fixed width
  height: 55%; // Fixed height
  position: relative;
  background-color: #fff; // Ensure it's visible
  justify-content: center; // Vertically center items
  align-items: center; // Horizontally center items
  box-sizing: border-box; // Include padding and border in dimensions
`;

const AlbumCover2 = styled.img`
  width: 100%;

  border-radius: 8px 8px 0 0; // Round top corners
`;

const SongInfo2 = styled.div`
  border: 1px solid rgba(222, 227, 235, 1);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center; // Center items vertically
  align-items: center; // Center items horizontally
  padding: 5px; // Adjust padding for aesthetics
  border-radius: 0 0 8px 8px; // Round bottom corners
  width: 100%; // Full width of the container
  box-sizing: border-box; // Include padding and borders in dimensions
  height: 50px; // Fixed height
`;

const SongTitle2 = styled.h3`
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  margin: 0; // Remove top margin
  font-size: 14px; // Ensure the font size is appropriate
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; // Adjust line height if needed
  max-height: calc(1.2em * 1); // Adjust to show only one line

  @media (max-width: 991px) {
    font-size: 12px; // Adjust font size for smaller screens
  }
`;

const Button = styled.button`
  align-self: center;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 32px;
  min-height: 56px;
  width: 258px;
  max-width: 100%;
  color: #fff;
  padding: 18px 12px;
  font: 600 14px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
`;

const PreviewContainer = styled.article`
  border-radius: 8px;
  align-self: center;
  display: flex;
  margin-top: 28px;
  width: 212px;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const ImageWrapper = styled.div`
  position: relative;
  aspect-ratio: 1.262;
  width: 100%;
`;

const BackgroundImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const OverlayImage = styled.img`
  aspect-ratio: 1.26;
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

const Title = styled.h2`
  background-color: #fff;
  width: 100%;
  color: #404a4c;
  text-align: center;
  letter-spacing: -0.14px;
  padding: 16px 12px;
  font: 600 14px/20px Poppins, sans-serif;
  border: 1px solid #dee3eb;
`;

const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  color: #fff;
  text-align: center;
  line-height: 1.2;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PopupContainer = styled.section`
  border-radius: 12px;
  background-color: #282828;
  position: relative;
  display: flex;
  max-width: 480px;
  flex-direction: column;
  overflow: hidden;
  padding: 36px 16px;
  width: 100%;

  /* Center content */
  justify-content: center;
  align-items: center; /* Aligns SongCardContainer to the center horizontally */
  margin: 0 auto; /* Centers PopupContainer horizontally within its parent */
  height: 350px; /* Optional: Adjusts height to center vertically within the view */
  @media (max-width: 991px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Greeting = styled.h1`
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.24px;
  align-self: center;
`;

const Message = styled.p`
  margin-top: 0px;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.16px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default SharePage;
