import React, { useState, useEffect, useRef } from "react";

import { styled, keyframes } from "styled-components";
import {
    Circle2,
    SpinnerContainer,
    Logo,
    LoadingOverlay1,
    LoadingText2,
    SongInfo2,
    SongTitle2,
    Spinner,
    Overlay,
} from "../../assets/styles/premium_user_styled";

import Footer from "../footer_lan";
import Pre_nav from "../navigation/premium_nav";
import axios from "axios";
import { getToken, getLoadingStatus, clearToken } from "../utils";
import config from "../../config";
import { useLocation, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import { getfirstname, getlastname } from "../utils";
import { edit_playlist,delete_playlist,white_close, next, pre, } from "../../assets/images";
import EditPlaylistForm from "./edit_playlist_name_popup";
import sort_by_descending from "../../assets/images/sort_by_descending.svg";
import sort_by_ascending from "../../assets/images/sort_by_ascending.svg";
import down_arrow from "../../assets/images/down_arrow.svg";
import right_arrow from "../../assets/images/right_arrow.svg";
import left_arrow from "../../assets/images/left_arrow.svg";
import sort_ascending from "../../assets/images/sort_ascending.svg";
import sort_descending from "../../assets/images/sort_descending.svg";
import YouTubesearch from "../user-page/search_popup";
import {  add_new_songs} from "../../assets/images";




const Playlist_Songs = () => {


    const token = getToken();

    const navigate = useNavigate();
    if (token == null) {
        navigate("/");
    }
    const location = useLocation();
  const { result1 } = location.state || {};
  const { result2 } = location.state || {};
  const { nav1 } = location.state || {};

    const [playlist, setplaylist] = useState([]);
    const [loading, setLoading] = useState(true);
    const [songs, setSongs] = useState([]);
    const [editDropdown, seteditDropdown] = useState(false);
    const [delDropdown, setdelDropdown] = useState(false);
    const [showSortBy, setShowSortBy]=useState("Sort by")
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortOrder1, setSortOrder1] = useState("asc");
    const [showDropdown1, setShowDropdown1] = useState(false);
    const [showDropdown2, setShowDropdown2] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [nextKeys, setNextKeys] = useState([]);
    const [nextKeys1, setNextKeys1] = useState("");
    const [currentPageIndex, setCurrentPageIndex] = useState(-1);
    const [call_back, setcall_back] = useState(false);

    const [pre1, setpre] = useState(false);

    const edit_playlist3 = localStorage.getItem("edit_playlist");
    const toggleDropdown = () => {
      setShowDropdown(!showDropdown);
    };

    

    useEffect(() => {
        get_playlist();
    }, []);


    useEffect(() => {
    if (edit_playlist3) {

      get_playlist();
      
      localStorage.removeItem('edit_playlist');

    }

  }, [edit_playlist3]);



  

    const show_edit_popup = () => {
      seteditDropdown(true);
    };

    const hide_edit_popup = () => {
      seteditDropdown(false);
    };

    const show_del_popup = () => {
      setdelDropdown(true);
    };

    const hide_del_popup = () => {
      setdelDropdown(false);
    };





    const get_playlist = async () => {
      try {
          const response = await axios.get(`${config.apiUrl}/playlists/${result1}`, {
              headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
              },
          });
  
          if (response.status === 200) {
            const nextPageKey = response.headers["x-next-page-key"] || null;
             setNextKeys((prevState) => [...prevState, nextPageKey]);
             setNextKeys1(nextPageKey);
              // Accessing the playlist object directly
              const playrecord = {
                  name: response.data.playlist.name,
                  image_url: response.data.playlist.image_url,
                  song_image_url: response.data.playlist.song_image_url,
                  total_songs: response.data.playlist.total_songs,
              };
  
              // Set the playlist state directly
              setplaylist(playrecord);
  
              const mappedRecords = response.data.records.map((record) => {
                  const thumbnailUrl = record.thumbnail_url.split("?")[0];
                  const song = {
                      title: record.title,
                      requestId: record.request_id,
                      url: record.url,
                      thumbnail_url: thumbnailUrl,
                      state: record.state,
                      created_at: record.created_at,
                  };
  
                  return song;
              });
  
              setSongs(mappedRecords);
              setLoading(false);
          }
      } catch (error) {
          if (error.response && error.response.status === 401) {
              clearToken();
              navigate("/");
          }
      }
  };

  const next_page = async (pageKey) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${config.apiUrl}/playlists/${result1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "x-page-key": pageKey,
          },
        }
      );

      if (response.status === 200) {
        const nextPageKey = response.headers["x-next-page-key"] || null;
         setNextKeys((prevState) => [...prevState, nextPageKey]);
         setNextKeys1(nextPageKey);
          // Accessing the playlist object directly
          // const playrecord = {
          //     name: response.data.playlist.name,
          //     image_url: response.data.playlist.image_url,
          //     song_image_url: response.data.playlist.song_image_url,
          //     total_songs: response.data.playlist.total_songs,
          // };

          // // Set the playlist state directly
          // setplaylist(playrecord);

          const mappedRecords = response.data.records.map((record) => {
              const thumbnailUrl = record.thumbnail_url.split("?")[0];
              const song = {
                  title: record.title,
                  requestId: record.request_id,
                  url: record.url,
                  thumbnail_url: thumbnailUrl,
                  state: record.state,
                  created_at: record.created_at,
              };

              return song;
          });

          setSongs(mappedRecords);
          setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const ImageComponent = ({ next, pre }) => (
    <ImageContainer>
      {pre1 && <NavImage src={pre} alt="Previous" onClick={previousPage} />}
      {nextKeys1 !== null && (
        <NavImage src={next} alt="Next" onClick={nextPage} />
       )}
    </ImageContainer>
  );


  const nextPage = () => {
    if (currentPageIndex < nextKeys.length - 1) {
      const nextPageKey = nextKeys[currentPageIndex + 1];
      setCurrentPageIndex((prevIndex) => prevIndex + 1);
      setpre(true);
      next_page(nextPageKey);
    }
  };

  const previousPage = () => {
    if (currentPageIndex > 0) {
      const prevPageKey = nextKeys[currentPageIndex - 1];
      setCurrentPageIndex((prevIndex) => prevIndex - 1);
      next_page(prevPageKey);
    } else {
      setCurrentPageIndex(-1);
      if (call_back === false) {
        get_playlist();
      }
     // fetchPlaylistSongs();
      setpre(false);
    }
  };
  


    const handleSongCardClick = async (requestId) => {

        try {
          const encodedRequestId1 = encodeURIComponent(requestId);
          const response_status = await axios.get(
            `${config.apiUrl}/url_requests/${encodedRequestId1}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
    
          if (response_status.status === 200) {
            if (response_status.data.state === "complete") {
              setLoading(true);
              visitchords(encodedRequestId1, response_status.data, response_status.data.youtube_id);
    
            }
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            clearToken();
            navigate("/");
          }
          // Handle error
        }
      };
    
      const first_name = getfirstname();
      const last_name = getlastname();
      const initials = first_name && last_name ? `${first_name}_${last_name}` : 'Unknown';
    
      const visitchords = async (requestId, responsed, youtube_id) => {
        try {
          const response = await fetch(
            `${config.apiUrl}/playlists/__default_playlist__/${requestId}/visit`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const data = await response.json();
          if (response.status === 200) {
            window.scrollTo(0, 0);
    
            navigate(`/chords/${initials}/youtube/${youtube_id}`, { state: { hiddenParam: 'user' } });
            // navigate("/chords", { state: { result1: responsed } });
          }
        } catch (error) {
          if (error.response && error.response.status === 401) {
            clearToken();
            navigate("/");
          }
        }
      };


      const Dropdown = ({ showDropdown, toggleDropdown }) => {
        const [searchVisible, setsearchVisible] = useState(false);
        const [showCreatedSubDropdown, setShowCreatedSubDropdown] = useState(false);
        const [showPlayedSubDropdown, setShowPlayedSubDropdown] = useState(false);
      
        const recent_play = async () => {
          try {
             setLoading(true);
            const response = await axios.get(
              `${config.apiUrl}/playlists/${result1}?&sort_by=visited_timestamp`,
              {
                params: { sort_direction: "asc" },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response.status === 200) {
              const mappedRecords = response.data.records.map((record) => {
                const thumbnailUrl = record.thumbnail_url.split("?")[0];
                return {
                  title: record.title,
                  requestId: record.request_id,
                  url: record.url,
                  thumbnail_url: thumbnailUrl,
                  created_at:record.created_at,
                };
              });
    
              setSongs(mappedRecords);
              setSortOrder("asc");
              setShowSortBy("Recently Played Ascending");
             
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              clearToken();
              navigate("/");
            }
          } finally {
            setLoading(false);
          }
        };
    
        const recent_play1 = async () => {
          try {
             setLoading(true);
            const response = await axios.get(
              `${config.apiUrl}/playlists/${result1}?&sort_by=visited_timestamp`,
              {
                params: { sort_direction:"desc" },
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response.status === 200) {
              const mappedRecords = response.data.records.map((record) => {
                const thumbnailUrl = record.thumbnail_url.split("?")[0];
                return {
                  title: record.title,
                  requestId: record.request_id,
                  url: record.url,
                  thumbnail_url: thumbnailUrl,
                  created_at:record.created_at,
                };
              });
    
              setSongs(mappedRecords);
              setSortOrder("desc");
              setShowSortBy("Recently Played Descending");
             
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              clearToken();
              navigate("/");
            }
          } finally {
            setLoading(false);
          }
        };
      
        // Fetch Created Date Sorted Songs
        const created_date = async () => {
          try {
            setLoading(true);
            const response = await axios.get(
              `${config.apiUrl}/playlists/${result1}?&sort_by=sk`,
              {
                params: { sort_direction: "asc"},
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response.status === 200) {
              const mappedRecords = response.data.records.map((record) => {
                const thumbnailUrl = record.thumbnail_url.split("?")[0];
                return {
                  title: record.title,
                  requestId: record.request_id,
                  url: record.url,
                  thumbnail_url: thumbnailUrl,
                  created_at:record.created_at,
                };
              });
              setSongs(mappedRecords);
              setSortOrder("asc");
              setShowSortBy("Created Date Ascending");
               
            
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              clearToken();
              navigate("/");
            }
          } finally {
            setLoading(false);
          }
        };
        const created_date1 = async () => {
          try {
            setLoading(true);
            const response = await axios.get(
              `${config.apiUrl}/playlists/${result1}?&sort_by=sk`,
              {
                params: { sort_direction: "desc"},
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (response.status === 200) {
              const mappedRecords = response.data.records.map((record) => ({
                title: record.title,
                requestId: record.request_id,
                url: record.url,
                thumbnail_url: record.thumbnail_url.split("?")[0],
                created_at: record.created_at,
              }));
              setSongs(mappedRecords);
              setSortOrder("desc");
              setShowSortBy("Created Date Descending");
          
            }
          } catch (error) {
            if (error.response && error.response.status === 401) {
              clearToken();
              navigate("/");
            }
          } finally {
            setLoading(false);
          }
        };
      
        const handleyouClick = () => setsearchVisible(true);
        const handleyouClose = () => setsearchVisible(false);
      
        const toggleCreatedSubDropdown = () => setShowCreatedSubDropdown(!showCreatedSubDropdown);
        const togglePlayedSubDropdown = () => setShowPlayedSubDropdown(!showPlayedSubDropdown);
      
        const id = "playlist";
      
        return (
          <DropdownContainer>
           
            <StyledIcon  onClick={toggleDropdown}>
              {sortOrder=="asc"?<IconDis loading ="lazy" src={sort_by_ascending} alt="sort"/>:
                <IconDis loading ="lazy" src={sort_by_descending} alt="sort"/>}
            
              <SortByText> {showSortBy} </SortByText>
              <Icon loading ="lazy" src={down_arrow} alt="sort"/>       
            </StyledIcon>
      
            <DropdownContent show={showDropdown}>
              {/* Main Dropdown Item for Created Date */}
              <DropdownItem onClick={toggleCreatedSubDropdown}>
                 <Icon loading="lazy" src={left_arrow} alt="Dropdown Icon"/>Created Date&nbsp;&nbsp;&nbsp;&nbsp;
              </DropdownItem>
      
              {/* Sub-dropdown for Created Date Sorting */}
              {showCreatedSubDropdown && (
                <DropdownContent1 show={true}>
                  <DropdownItem onClick={created_date}>Sort by Ascending&nbsp;&nbsp;<Icon loading ="lazy" src={sort_ascending} alt="sort"/></DropdownItem>
                  <DropdownItem onClick={created_date1}>Sort by Descending<Icon loading ="lazy" src={sort_descending} alt="sort"/></DropdownItem>
                </DropdownContent1>
              )}
              {/* Main Dropdown Item for Recently Played */}
              <DropdownItem onClick={togglePlayedSubDropdown}>
                 <Icon loading="lazy" src={left_arrow} alt="Dropdown Icon"/>Recently Played
              </DropdownItem>
              {/* Sub-dropdown for Recently Played Sorting */}
              {showPlayedSubDropdown && (
                <DropdownContent1 show={true}>
                  <DropdownItem onClick={recent_play}>Sort by Ascending&nbsp;&nbsp;<Icon loading ="lazy" src={sort_ascending} alt="sort"/></DropdownItem>
                  <DropdownItem onClick={recent_play1}>Sort by Descending<Icon loading ="lazy" src={sort_descending} alt="sort"/></DropdownItem>
                </DropdownContent1>
              )}
            </DropdownContent>
            {/* Conditional Popup for YouTube Search */}
            {/* {searchVisible && (
              <PopupOverlay>
                <YouTubesearch onClose={handleyouClose} i d={id} />
              </PopupOverlay>
            )} */}
      
            {/* Add new song button */}
            {/* <Newsong src={add_new_songs} onClick={handleyouClick} /> */}
          </DropdownContainer>
        );
      };







    return (
        <AppContainer>
            <Pre_nav />
            <Overlay loading={loading}>
                <Spinner />

            </Overlay>
            <SongListContainer>
                <Header>
                    <MainTitle>{playlist.name}</MainTitle>
                    <Image1 onClick={show_edit_popup} src={edit_playlist}/>
                    <Image1  onClick={show_del_popup} src={delete_playlist}/>

                    <div style={{ marginLeft: 'auto' }}>
    <Dropdown
      showDropdown={showDropdown}
      toggleDropdown={toggleDropdown}
    />
  </div>

                </Header>





                <SongGrid>
                    {songs.map((song) => (
                        <SongCardContainer
                            key={song.requestId}
                            onClick={() => handleSongCardClick(song.requestId)}
                        >
                            <AlbumCover
                                loading="lazy"
                                src={song.thumbnail_url}
                                alt={`Album cover for ${song.title}`}
                            />

                            <SongInfo2>
                                <SongTitle2>{song.title}</SongTitle2>
                                <Songdate>Created date : {song.created_at}</Songdate>
                            </SongInfo2>
                        </SongCardContainer>
                    ))}
                </SongGrid>


            </SongListContainer>
            <ImageComponent next={next} pre={pre} />

            {editDropdown && (
          <PopupOverlay>
            <EditPlaylistForm onClose={hide_edit_popup} name={playlist.name} id1={result1} />
          </PopupOverlay>
        )}

        {delDropdown && (
          <PopupOverlay>
            <Downgrade onClose={hide_del_popup} id1={result1} nav={nav1} />
          </PopupOverlay>
        )}

            <ToastContainer />
            <Footer />

        </AppContainer>
    );
};


function Downgrade({ onClose,id1,nav }) {
  const token = getToken();
 
  const [loading, setLoading] = useState(false);
  
  
  
 
  const navigate = useNavigate();

  const delete_playlist = async () => {
    setLoading(true);
    const encodedRequestId1 = encodeURIComponent(id1);

    try {
      const response = await axios.delete(
        `${config.apiUrl}/playlists/${encodedRequestId1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if(nav==="user"){
          navigate("/user");
        }else if(nav==="all_playlist"){
          navigate("/playlist");
        }
       
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } 
    } finally {
      setLoading(false);
    }
  };

  const ActionButton = ({ text, primary, onClick }) => {
    return (
      <Button primary={primary} onClick={onClick}>
        {text}
      </Button>
    );
  };

  return (
    <Modal>
      <HeaderContainer1>
        <WarningText>Warning</WarningText>
        <CloseIcon onClick={onClose} loading="lazy" src={white_close} alt="Close" />
      </HeaderContainer1>
      <Content1>Are you sure you want to delete this playlist?<br></br> This action cannot be undone.</Content1>
      <ActionButton text="Confirm" primary onClick={delete_playlist} />
      <ActionButton text="Cancel" onClick={onClose} />
    </Modal>
  );
}

export default Playlist_Songs;


const Content1 = styled.p`
  color: #3e3635;
  letter-spacing: 0.1px;
  margin-top: 37px;
  font: 500 18px/21px Poppins, sans-serif;
`;

const HeaderContainer1 = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 428px;
  background-color: #0072D5;
  max-width: 100%;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  height:70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
`;

const WarningText = styled.h2`
  margin: 0 auto;
  font: 600 24px Poppins, sans-serif;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
`;
const Button = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${props => props.primary ? "#0072D5" : "transparent"};
  align-self: center;
  margin-top: ${props => props.primary ? "15px" : "5px"};
  width: 100%;
  max-width: 296px;
  color: ${props => props.primary ? "#fff" : "#717171"};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;

const ActionButton = styled.img`
  background: none;
  border: none;

  cursor: pointer;
  padding: 0;
  gap:20px;
  width: 180px;
  img {
    aspect-ratio: 1;
    object-fit: contain;
    width: 20px;
  }
`;

const Modal = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  height:300px;
  padding: 0 0 0px;
   flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    width: 90%;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const Songdate = styled.h5`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin: 0;
  margin-top:7px;
  font-size: 13px; 
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; 
  max-height: calc(1.2em * 2); 
 
  
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;

const AlbumCover = styled.img`
  aspect-ratio: 1.27;

  width: 100%;
  border-radius: 8px 8px 0 0;
  @media (max-width: 991px) {
    width: 100%;
    height: auto;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center; /* Align items vertically in the center */
  justify-content: flex-start; /* Align items to the left */
  gap: 12px; /* Add spacing between title and images */
  padding: 12px 0; /* Optional padding */
`;

const MainTitle = styled.h1`
  color: #fff;
  letter-spacing: -0.24px;
  font: 600 24px/160% "Poppins", -apple-system, Roboto, Helvetica, sans-serif;
  margin-left: 10px; /* Remove extra margin */
  @media (max-width: 991px) {
    font-size: 18px;
  }
`;

const StyledIcon = styled.div`
  width: auto;
  cursor: pointer;
  height: 55px; /* Corrected typo */
  padding: 0px 10px ;
  background-color: white;
  display: flex;  
  justify-content:space-around;  
  align-items: center; 
  border-radius:8px;
   margin-right:30px;
  @media (max-width: 991px) {
    width: auto;
    height:30px;
    border-radius:4px;
    margin-right:30px;

  }
`;

const SortByText = styled.h4`
  color: black;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin: 0 10px;
  white-space: nowrap; /* Ensures text doesn't wrap */
  overflow: hidden; /* Optional: hides overflow if the text is too long */
  text-overflow: ellipsis; /* Optional: shows "..." if text overflows */
  
  @media (max-width: 991px) {
    font-size: 8px;
  }
`;


const Image1 = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer; /* Optional: to indicate clickable icons */
`;


const SongTitle4 = styled.h3`
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-top: 10px;
  font-size: 14px; // Ensure the font size is appropriate
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2; // Adjust line height if needed
  max-height: calc(1.2em * 2); // Line height * number of lines
  
  @media (max-width: 991px) {
    font-size: 12px;
  }
`;

const SongGridall = styled.section`
  display: flex;
  flex-direction: row;
  gap: 20px; // Add a gap between the grids if needed
  @media (max-width: 991px) {
    flex-direction: column; // Stack vertically on small screens
    gap: 10px;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;  // Align items vertically in the center
  gap: 10px;           
  justify-content: flex-end; 
   position: relative;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  justify-content: end;
  font-size: 24px;
  color: white;
`;
const IconDis = styled.img`
  width: 30px;
  height: 30px;
  justify-content: end;
  font-size: 24px;
  color: white;
   @media (max-width: 991px) {
    width:15px;
    height:15px;
  }
`;

const DropdownContent = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 100%; /* Directly below the StyledIcon */
  left: 0; /* Aligned with the StyledIcon */
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  width: 170px;

  @media (max-width: 991px) {
    width: auto;
  }
`;

const DropdownContent1 = styled.div`
  display: ${({ show }) => (show ? "block" : "none")};
  position: absolute;
  top: 0; /* Aligned with DropdownContent */
  right: 100%; /* Positioned to the right of DropdownContent */
  background-color: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
  width: 200px;

  @media (max-width: 991px) {
    width: 150px;
  }

`;


const DropdownItem = styled.div`
  padding: 12px 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between; /* Aligns elements at the left and right corners */
  align-items: center; /* Ensures the elements are vertically aligned */
  &:hover {
    background-color: #f1f1f1;
  }
`;



const AppContainer = styled.div`
  display: flex;
  flex-direction: column;

`;






const SongListContainer = styled.main`
  display: flex;
  flex-direction: column;
   min-height: 80vh;
  font-size: 16px;
  color: #020f12;
  letter-spacing: -0.16px;
  line-height: 140%;
  padding: 20px 32px;
  background-color: #020f12;
  height: 100%;
  @media (max-width: 991px) {
     padding: 0px;
     width:100%
  }
`;



const SongGrid = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-left:12px;
  justify-content: flex-start;
  margin-top: 20px;
  @media (max-width: 991px) {
    gap: 5px;
  }
`;

const SongCardContainer = styled.article`
  cursor: pointer;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  width: 212px;
  height: 258px;
  position: relative;
  @media (max-width: 991px) {
    width: calc(33.33% - 10px);
    height: auto;
    margin-bottom: 10px;
  }
`;

const Newsong = styled.img`
  
  cursor: pointer;
  object-fit: contain;
  width:190px;
  @media (max-width: 991px) {
    width: 110px;
  }

`;


const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #020f12;
  width: 100%;
  padding: 10px 0;
   @media (max-width: 991px) {
     padding: 10px 0;
  }
`;

const NavImage = styled.img`
  margin: 0 10px;
  cursor: pointer;
`;

