
import React, {  useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './components/landing/landing_page';
import Premium_user from "./components/user-page/user1";
import Playlist from "./components/user-page/playlist";
import MusicApp from "./components/chords/chords_url";
import MusicApp1 from "./components/chords/chords_url1";
import MusicApp2 from "./components/chords/chords1";
import Chords_Login from './components/login/chords_login';
import Public_song from "./components/user-page/view_all_public_songs";

import All_Playlist from "./components/playlist/all_playlist";
import Playlist_Songs from "./components/playlist/playlist_songs";

import Myprofile from "../src/components/user-page/myprofile";
import Mybilling from './components/user-page/mybilling';
import Settings from './components/user-page/settings';
import Contact from './components/user-page/contact';
import Youtube_song from './components/user-page/view_all_youtube';
import FAQSection from './components/landing/faq';
import About_us from './components/about_us/first_section';
import PrivacyPolicy from './components/landing/Privacy_policy';
import TermsAndConditions from './components/landing/termsandcondition';
import SharePage from './components/chords/share_receive_popup';

import FeedbackPopup from './components/user-page/feedback';
// import CookieConsent from './components/user-page/cookie_consent';



function App() {
  // useEffect(() => {
  //   // This will clear localStorage when the user closes the website
  //   const handleUnload = () => {
  //     localStorage.clear();
  //   };

  //   // Add event listener when the component mounts
  //   window.addEventListener('beforeunload', handleUnload);

  //   // Cleanup event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleUnload);
  //   };
  // }, []);

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/login" element={<Chords_Login />} />
          <Route path="/user" element={<Premium_user />} />
          <Route path="/chords/:initials/youtube/:youtube_id" element={<MusicApp />} />
          <Route path="/chord/:initials/youtube/:youtube_id" element={<MusicApp1 />} />
          <Route path="/songs" element={<Playlist_Songs />} />
          <Route path="/chords/public/youtube/:id" element={<MusicApp2 />} />
          <Route path="/all_songs" element={<Playlist />} />
          <Route path="/public_songs" element={<Public_song />} />
          <Route path="/myprofile" element={<Myprofile />} />
          <Route path="/mybilling" element={<Mybilling />} />
          <Route path="/thankyou" element={<Premium_user />} />
         
          <Route path="/settings" element={<Settings />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQSection />} />
          <Route path="/about_us" element={<About_us />} />
          <Route path="/playlist" element={<All_Playlist />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/youtube" element={<Youtube_song />} />
          <Route path="/share" element={<Premium_user />} />


          {/* <Route path="/feedback" element={<FeedbackPopup />} /> */}


          {/* <Route path="/feedback" element={<FeedbackPopup />} /> */}
          




        </Routes>
      </div>
    </Router>
  );
}

export default App;
