import React, { useEffect, useRef, useState } from "react";
// import styled from "styled-components";
import axios from "axios";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Cookies from "js-cookie";
import FeedbackPopup from "../user-page/feedback";
import html2pdf from "html2pdf.js";
import ReactDOMServer from "react-dom/server";
import { FaEdit, FaTrash } from "react-icons/fa";

import {
  new_logo_white,save_as_btn,
  add_icon_playlist,
  down_drop_arrow,
  auto_scroll,
  copy_url,
  delete_button,
  download_blue,
  download_free,
  edit_chords,
  retry_chords,
  save_edit,
  white_close,
  add_to_playlisted,
  new_logo,
  share_btn,
  back_arraow_chords,
  start_scroll,
  edt_variant,
  del_variant,
  green_tick_edit,exit_edt_mode,
} from "../../assets/images";
import arrow from "../../assets/images/arrow.svg";
import tick_snack_bar from "../../assets/images/tick_snack_bar.svg";
import arrow_drop from "../../assets/images/tick_snack_bar.svg";
import config from "../../config";
import Footer from "../footer_lan";
import Pre_nav from "../navigation/premium_nav";
import PlaylistForm from "../playlist/add_playlist_popup";
import CreatePlaylistPopup from "../user-page/playlist_popup";
import useSubscription from "../user-page/subscription";
import { clearToken, getToken } from "../utils";
import VideoPlayer from "./VideoPlayer";

import Snackbar from "@mui/material/Snackbar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getfirstname, getlastname } from "../utils";
import ShareLinkPopup from "./share_popup";

const CustomSnackbar = styled(Snackbar)(({ theme }) => ({
  "& .MuiSnackbarContent-root": {
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    color: "black",
  },
  "& .MuiSnackbarContent-message": {
    display: "flex",
    alignItems: "center",
  },
}));

const CustomToast = ({ closeToast }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    {/* <FaUser style={{ marginRight: '10px' }} /> */}
    <img
      src={tick_snack_bar}
      alt="check icon"
      style={{ marginRight: "8px", width: "24px", height: "24px" }}
    />

    <span style={{ flex: 1 }}>link copied</span>
  </div>
);
const showToast1 = () => {
  toast(<CustomToast1 message={"Playlist Updated Successfully"} />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

const CustomToast1 = ({ message }) => {
  return (
    <div>
      <p>{message}</p>
    </div>
  );
};

const showToast = () => {
  toast(<CustomToast />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

const MusicApp1 = () => {
  const { youtube_id } = useParams();
  const { initials } = useParams();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;
  const token = getToken();
  const navigate = useNavigate();
  if (token == null) {
    navigate("/");
  }
  const location = useLocation();
  const { result1 } = location.state || {};
  const [playlists, setPlaylists] = useState([]);
  const [timesArray, settimesArray] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  //const [subscription1, setSubscription] = useState("null");
  const [title1, settitle1] = useState("");
  const [thumb_img, setthumb_img] = useState("");

  const [Text1, setText1] = useState("");
  const [snackbar_name, setsnackbar_name] = useState("");
  const [Percentage, setPercentage] = useState("");

  const [result, setresult] = useState("");
  const [result_Cords, setresult_Cords] = useState([]);
  const [chordsTs, setchordsTs] = useState([]);
  const [state, setstate] = useState([]);
  const [wordsTs, setwordsTs] = useState([]);
  const [uniqueChords, setUniqueChords] = useState([]);
  const [isAutoTempoEnabled, setIsAutoTempoEnabled] = useState(false);
  const [url, seturl] = useState("");
  const [id, setid] = useState("");
  const first_name = getfirstname();
  const last_name = getlastname();
  const [selectedOption, setSelectedOption] = useState("Scroll: Auto Tempo");
  const initials1 =
    first_name && last_name ? `${first_name}_${last_name}` : "Unknown";
  let isuser = false;

  const playlist_added_successfully = localStorage.getItem(
    "playlist_added_successfully"
  );

  const subscription1 = useSubscription();

  const [open, setOpen] = useState(false);
  const [down, setDown] = useState(false);
  const [activeValue, setActiveValue] = useState(0);
  const [transposedValue, setTransposedValue] = useState(0);
  const [activeButton, setActiveButton] = useState(null);

  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const scrollIntervalRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [imageSrc, setImageSrc] = useState(edit_chords);
  const [combinedRowsWithMaxWidths, setCombinedRowsWithMaxWidths] = useState(
    []
  );
  const [myVariable, setMyVariable] = useState([]);

  const [selectedName, setSelectedName] = useState("formattedName");
  const [showplaylist_popup, setshowplaylist_popup] = useState(false);

  let isUserScrolling = false;
  let lastScrollPosition = window.pageYOffset;

  const hiddenParam = location.state?.hiddenParam;
  const [showDropdown, setShowDropdown] = useState(false);
  const [scrollSpeed, setScrollSpeed] = useState(0);

  const [scrollDelay, setScrollDelay] = useState(50);
  const [fontSize1, setFontSize1] = useState(16);

  const [dragging, setDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [visitCount, setVisitCount] = useState(0);
  const [show_feedback, setshow_feedback] = useState(false);
  const [share_popup, setshare_popup] = useState(false);
  const [share_url1, setshare_url1] = useState("");
  const isFirstMount = useRef(true);
  const [fontSize, setFontSize] = useState(15); // Default font size
  const [activeButton1, setActiveButton1] = useState(null); // Track the active button

  const [isActive, setIsActive] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showversion_title_popup, setshowversion_title_popup] = useState(false);
  const [saveas_original, setsaveas_original] = useState(false);
  const [showedt_txt_popup, setshowedt_txt_popup] = useState(false);
  const [showversion_edit_popup, setshowversion_edit_popup] = useState(false);
  const [showversion_del_popup, setshowversion_del_popup] = useState(false);
  const [edt_name, setedt_name] = useState("");
  const [del_name, setdel_name] = useState("");
  const [variants, setVariants] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedValue, setSelectedValue] = useState("original");
  const timeoutRef = useRef(null);
  const timeoutRef1 = useRef(null);
  const toastId = useRef(null);
  const [selectedChordIndex, setSelectedChordIndex] = useState(null); // Track which chord is selected
  const [editedChord, setEditedChord] = useState(""); // For editing chord text
  const [editedLyrics, setEditedLyrics] = useState("");

  const [editable, setEditable] = useState({ row: null, index: null });
  const [rowIndex, setrowIndex] = useState("");
  const [itemIndex, setitemIndex] = useState("");
  const [displayedWord, setdisplayedWord] = useState("");
  const [wordIndex, setwordIndex] = useState("");
  const [updatedText, setupdatedText] = useState("");
  const [lastitem, setlastitem] = useState("");

  const [rowIndex1, setrowIndex1] = useState("");
  const [itemIndex1, setitemIndex1] = useState("");
  const [displayedChord, setdisplayedChord] = useState("");
  const [chordIndex, setchordIndex] = useState("");
  const [updatedchord, setupdatedchord] = useState("");
  const [finalRows, setFinalRows] = useState([]);

  const showToast4 = (data) => {
    // If the toast is already active, update it
    if (toastId.current) {
      toast.update(toastId.current, {
        render: <CustomToast4 message={data} />,
        // autoClose: 5000, // Keep the toast open but automatically close after 5 seconds of inactivity
      });
    } else {
      // Create a new toast if it doesn't already exist
      toastId.current = toast(<CustomToast4 message={data} />, {
        position: "bottom-left",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        autoClose: false, // Keep the toast open until manually closed or updated
      });
    }
  };

  const CustomToast4 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };

  const showToast5 = (data) => {
    toast(<CustomToast1 message={data} />, {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const CustomToast1 = ({ message }) => {
    return (
      <div>
        <p>{message}</p>
      </div>
    );
  };

  const handleToggle2 = () => {
    setIsAutoTempoEnabled(!isAutoTempoEnabled);
  };

  const handleyouClick_retryversion = () => {
    setshowversion_title_popup(true);
  };

  const handleyouClick_retryversion1 = () => {
    setshowversion_title_popup(false);
  };


  const handle_save_as_original_popup = () => {
    setsaveas_original(true);
  };

  const handle_save_as_original_popup1 = () => {
    setsaveas_original(false);
  };

  const handleyouClick_edittext = () => {
    setshowedt_txt_popup(true);
  };

  const handleyouClick_edittext1 = () => {
    setshowedt_txt_popup(false);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    if (value === "Manual") {
      setSelectedOption("Scroll: Manual");
    } else {
      setSelectedOption("Scroll: Auto Tempo");
    }
  };

  //   const handleSelectChange_version_name = (event) => {
  //     const selectedName = event.target.value;
  //     get_variant(selectedName);
  // };

  const handleSelectChange_version_name = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue === "original") {
      get_original_variant();
    } else {
      get_variant(selectedValue);
    }
  };

  const handleEdit = (variantName) => {
    setedt_name(variantName);
    setIsOpen(false);
    setshowversion_edit_popup(true);
  };

  const handleEdit_close = () => {
    setedt_name("");
    setshowversion_edit_popup(false);
  };

  const handleDelete = (variantName) => {
    setdel_name(variantName);
    setIsOpen(false);
    setshowversion_del_popup(true);
  };

  const handleDelete_close = () => {
    setdel_name("");
    setshowversion_del_popup(false);
  };

  useEffect(() => {
    if (Text1) {
      const text2 = `${snackbar_name} - ${Text1}`;
      showToast4(text2);

      // Close the toast if Text1 is "Complete"
      if (Text1 === "Finalizing") {
        setTimeout(() => {
          if (toastId.current) {
            toast.dismiss(toastId.current); // Dismiss the toast after 5 seconds
            toastId.current = null; // Clear the toast ID
          }
        }, 7000); // 5000 ms = 5 seconds
      }
    }
  }, [Text1]);

  const handleSelectChange4 = (eventOrValue) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); // Clear any existing timeout
    }

    const selectedValue =
      typeof eventOrValue === "string"
        ? eventOrValue
        : eventOrValue.target.value;

    setSelectedValue(selectedValue);

    if (selectedValue === "original") {
      setIsEditing(false);
      setEditable({ row: null, index: null });
      get_original_variant();
    } else {
      get_variant(selectedValue);
    }
    setIsOpen(false);
  };

  const get_variant1 = async (name) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        let hasInProgress = false;
        setstate(response.data.state);
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);

        setLoading1(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_variant = async (name) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);

      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        let hasInProgress = false;

        setsnackbar_name(name);

        setstate(response.data.state);
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);

        if (
          Array.isArray(response.data.chords_ts) &&
          response.data.chords_ts.length === 0
        ) {
          if (
            response.data.state === "pending" ||
            response.data.state === "in_progress"
          ) {
            setLoading1(true);

            const step = response.data.processing_step;
            let progressPercentage;
            let text;

            if (step === "generating_mp3") {
              progressPercentage = 10;
              text = "Generating Mp3";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "generating_midi") {
              progressPercentage = 25;
              text = "Generating Midi";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "transcribing_audio") {
              progressPercentage = 50;
              text = "Transcribing Audio";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "generating_chords") {
              progressPercentage = 75;
              text = "Generating Chords";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "finalizing") {
              progressPercentage = 95;
              text = "Finalizing";
              setText1(text);
              setPercentage(progressPercentage);
            } else if (step === "complete") {
              progressPercentage = 100;
              text = "Complete";
              setText1(text);
              setPercentage(progressPercentage);
            }

            timeoutRef.current = setTimeout(() => get_variant(name), 5000);
          }
        } else {
          setTimeout(() => {
            if (toastId.current) {
              toast.dismiss(toastId.current); // Dismiss the toast after 5 seconds
              toastId.current = null; // Clear the toast ID
            }
          }, 100);
          setLoading1(false);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_original_variant = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(youtube_id);
      const response = await axios.get(
        `${config.apiUrl}/url_requests/youtube/${encodedRequestId1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  // const alternativeOption = selectedOption === "Scroll: Manual"
  // ? { value: "Auto Tempo", label: "Scroll : Auto Tempo" }
  // : { value: "Manual", label: "Scroll : Manual" };

  // Effect to update the option text
  // useEffect(() => {
  //   const selectElement = document.getElementById("tempo");
  //   if (selectElement) {
  //     const options = selectElement.options;
  //     for (let i = 0; i < options.length; i++) {
  //       if (options[i].value === "Manual") {
  //         options[i].text = "Scroll : Manual";
  //       } else if (options[i].value === "Auto Tempo") {
  //         options[i].text = "Scroll : Auto Tempo";
  //       }
  //     }
  //   }
  // }, [selectedOption]);

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;

      const today = new Date().toISOString().split("T")[0]; // Get the current date (YYYY-MM-DD)
     
      // Retrieve the last visit date and visit count from cookies
      const lastVisitDate = Cookies.get("lastVisitDate");
     
      const visits = Cookies.get("chordsPageVisitCount")
        ? parseInt(Cookies.get("chordsPageVisitCount"))
        : 0;

      

      // Check if it's a new day to reset the count
      if (lastVisitDate !== today) {
        Cookies.set("chordsPageVisitCount", 0, { expires: 7 });
        Cookies.set("lastVisitDate", today, { expires: 7 });
        setVisitCount(0);
      } else {
        // Use the existing visit count
        setVisitCount(visits);

        // If the visit count is 3 or more
        if (visits >= 3) {
          const feedbackSubmitted = Cookies.get("feedbackSubmitted");
          const feedbackClosedWithoutSubmit = Cookies.get(
            "feedbackClosedWithoutSubmit"
          );

        

          if (!feedbackSubmitted) {
            // 24 hrs full days
            if (feedbackClosedWithoutSubmit) {
              const lastClosedDate = new Date(feedbackClosedWithoutSubmit);
              const currentDate = new Date();

             

              const isSameDate =
                lastClosedDate.toDateString() === currentDate.toDateString();
             

              if (!isSameDate) {
                const timeDifference = currentDate - lastClosedDate;

                const oneDayInMilliseconds = 1000 * 60 * 60 * 24;

                if (timeDifference >= oneDayInMilliseconds) {
                  setshow_feedback(true);
                }
              }
            }

            // Midnight 12
            // if (feedbackClosedWithoutSubmit) {
            //   const lastClosedDate = new Date(feedbackClosedWithoutSubmit);
            //   const currentDate = new Date();

         
            //   const midnightLastClosedDate = new Date(lastClosedDate);
            //   midnightLastClosedDate.setHours(0, 0, 0, 0);

            //   const nextDayDate = new Date(midnightLastClosedDate);
            //   nextDayDate.setDate(midnightLastClosedDate.getDate() + 1);

            //   if (currentDate >= nextDayDate) {
            //     setshow_feedback(true);
            //   }
            // }
            else {
              // Show feedback popup if it has not been closed or submitted yet
              setshow_feedback(true);
            }
          }
        }
      }

      // Increment visit count and save it to cookies
      const newVisitCount = visits + 1;
      Cookies.set("chordsPageVisitCount", newVisitCount, { expires: 7 });
      setVisitCount(newVisitCount);
    }
  }, []);

  const handleFeedbackSubmit = () => {
    // Set a cookie for 7 days after feedback is submitted
    Cookies.set("feedbackSubmitted", "true", { expires: 7 });
    setshow_feedback(false);
  };

  const handleFeedbackClose = () => {
    // Store the current date when the user closes the feedback without submitting
    const currentDate = new Date().toISOString();
    Cookies.set("feedbackClosedWithoutSubmit", currentDate, { expires: 1 });
    setshow_feedback(false);
  };

  useEffect(() => {
    if (playlist_added_successfully) {
      showToast1();
      localStorage.removeItem("playlist_added_successfully");
    }
  }, [playlist_added_successfully]);

  const toggleDropdown = () => {
    if (!showDropdown) {
      get_playlist(); // Call get_playlist if dropdown is hidden
    } else {
      setShowDropdown(false); // Hide the dropdown if it's currently visible
    }
  };

  const handleyouClick_playlist = () => {
    setshowplaylist_popup(true);
  };

  const Go_back = () => {
    window.history.back();
  };

  const handleyouClick_playlist1 = () => {
    setshowplaylist_popup(false);
  };

  // const toggleAutoScroll = () => {
  //   if (isAutoScrolling) {
  //     stopAutoScroll();
  //   } else {
  //     startAutoScroll();
  //   }
  // };

  // // Start auto-scrolling with current speed
  // const startAutoScroll = () => {
  //   setIsAutoScrolling(true);
  //   scrollIntervalRef.current = setInterval(() => {
  //     window.scrollBy(0, scrollSpeed);

  //     // Stop scrolling at the end of the page
  //     if (
  //       window.innerHeight + window.pageYOffset >=
  //       document.body.offsetHeight
  //     ) {
  //       stopAutoScroll();
  //     }
  //   }, scrollDelay);
  // };

  // // Stop the auto-scroll by clearing the interval
  // const stopAutoScroll = () => {
  //   clearInterval(scrollIntervalRef.current);
  //   setIsAutoScrolling(false);
  // };

  const increaseScrollSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.min(prevSpeed + 1, 5));
  };

  const decreaseScrollSpeed = () => {
    setScrollSpeed((prevSpeed) => Math.max(0, prevSpeed - 1, 0));
  };

  useEffect(() => {
    // Clear the previous interval if speed changes
    if (scrollIntervalRef.current) {
      clearInterval(scrollIntervalRef.current);
    }

    // If scrollSpeed is greater than 0, set up a new interval
    if (scrollSpeed > 0) {
      scrollIntervalRef.current = setInterval(() => {
        window.scrollBy(0, scrollSpeed); // Scroll the page vertically based on the scrollSpeed
      }, 100); // Adjust this value to control the smoothness of scrolling
    }

    // Cleanup interval when the component unmounts or scrollSpeed is 0
    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
        scrollIntervalRef.current = null;
      }
    };
  }, [scrollSpeed]);

  // // Effect to reset the interval when the scroll speed changes and auto-scrolling is active
  // useEffect(() => {
  //   if (isAutoScrolling) {
  //     stopAutoScroll();
  //     startAutoScroll();
  //   }
  //   // Cleanup when component unmounts or auto-scroll is stopped
  //   return () => clearInterval(scrollIntervalRef.current);
  // }, [scrollSpeed]);

  // useEffect(() => {
  //   return () => {
  //     stopAutoScroll(); // Stop auto-scroll when the component is unmounted or navigating away
  //   };
  // }, []);

  const downgrade1 = () => {
    setDown(true);
  };

  let videoUrl = `https://www.youtube.com/embed/${youtube_id}`;
  let videoId;

  const get_id_url = async () => {
    try {
      const encodedRequestId1 = encodeURIComponent(youtube_id);
      const response = await axios.get(
        `${config.apiUrl}/url_requests/youtube/${encodedRequestId1}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        settitle1(response.data.title);
        const thumbnailUrl = response.data.thumbnail_url.split("?")[0];
        setthumb_img(thumbnailUrl);
        setresult(response.data.result);
        // setresult_Cords(response.data.chords);
        // setchordsTs(response.data.chords_ts_formatted);
        // setwordsTs(response.data.words_ts_formatted);
        setchordsTs(response.data.chords_ts);
        setwordsTs(response.data.words_ts_newline);
        seturl(response.data.url);
        setid(response.data.request_id);
        get_retry_version(response.data.request_id);

        const seen = new Set();
        const unique = response.data.chords.filter((chord) => {
          const formattedName = formatChordName(chord);
          if (seen.has(formattedName)) {
            return false;
          } else {
            seen.add(formattedName);
            return true;
          }
        });
        setUniqueChords(unique);

        if (url.includes("=")) {
          const parts = url.split("=");
          videoId = parts[1];
        } else {
          const parts = url.split("/");
          videoId = parts[parts.length - 1];
        }

        videoUrl = `https://www.youtube.com/embed/${youtube_id}`;
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const get_retry_version = async (id) => {
    try {
      const encodedRequestId1 = encodeURIComponent(id);
      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const variantData = response.data.variants.map((variant) => ({
          requestId: variant.request_id,
          name: variant.name,
          state: variant.state,
        }));
        setVariants(variantData);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    setLoading(true);

    const fetchPlaylists = async () => {
      try {
        const response = await axios.get(
          `${config.apiUrl}/playlists/__default_playlist__`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const records = response.data.records;
          if (records.length === 0) {
            // setCount(0);
          } else {
            const mappedPlaylists = records
              .filter((record) => record.state === "complete")
              .map((record) => {
                const thumbnailUrl = record.thumbnail_url.split("?")[0];
                return {
                  title: record.title,
                  url: record.url,
                  id: record.request_id,
                  image: thumbnailUrl,
                };
              });

            const ids = mappedPlaylists.map((playlist) => playlist.id);
            for (let i = 0; i < ids.length; i++) {
              let id = ids[i].split("#");
              if (youtube_id === id[1]) {
                isuser = true;
              }
            }

            setPlaylists(mappedPlaylists.slice(0, 11));
          }
          setLoading(false);
        }
        if (!isuser) {
          navigate("/");
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        }
       
      }
    };

    fetchPlaylists();
    get_id_url();
  }, []);

  const get_playlist = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}/playlists/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        const playlistsData = response.data.map((item) => ({
          name: item.name,
        }));
        setPlaylists(playlistsData);
        setShowDropdown(true); // Show the dropdown after data is fetched
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const [PopupVisible, setPopupVisible] = useState(false);

  const closepopup = () => {
    setPopupVisible(false);
    window.location.reload();
  };
  let startIndex = "";
  let endIndex = "";
  if (result.includes("```")) {
    startIndex = result.indexOf("```") + 3;
    endIndex = result.lastIndexOf("```");
  } else {
    startIndex = result.indexOf("") + 3;
    endIndex = result.lastIndexOf("");
  }

  let lines = [];
  if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
    const extractedText = result.substring(startIndex, endIndex).trim();
    lines = extractedText.split("\n");
  }
  let downlines = [];

  const checkAndColorLines = (line) => {
    const hasMoreThan3Spaces = line.match(/\s{3,}/);
    const segments = [];
    let currentSegment = "";
    for (let i = 0; i < line.length; i++) {
      if (line[i] === " ") {
        if (currentSegment !== "") {
          segments.push(currentSegment);
          currentSegment = "";
        }
        segments.push(" ");
      } else {
        currentSegment += line[i];
      }
    }
    if (currentSegment !== "") {
      segments.push(currentSegment);
    }
    if (hasMoreThan3Spaces || line.length < 2) {
      downlines.push(line);
      // let spaceCount = segments.filter(segment => segment === ' ').length;
      return (
        <Verse>
          {segments.map((segment, index) =>
            segment === " " ? (
              <React.Fragment key={index}>&nbsp;</React.Fragment>
            ) : (
              <span
                key={index}
                style={{
                  backgroundColor: "#F0984B",
                  padding: "2px 4px",
                  borderRadius: "4px",
                  color: "#FFFFFF",
                }}
              >
                {segment}
              </span>
            )
          )}
        </Verse>
      );
    } else {
      downlines.push(line);
      return <Verse style={{ color: "#FFFFFF" }}>{line}</Verse>;
    }
  };

  const generatePDF = () => {
    // Get the current window dimensions
    const width = window.innerWidth;
    const height = window.innerHeight;

    // Construct the logo HTML
    const logo = `<img src="${new_logo_white}" alt="Logo" style="width: 250px;">`; // Adjust width as needed

    // Open a new window with the same dimensions
    const newWindow = window.open(
      "",
      "_blank",
      `width=${width},height=${height}`
    );

    // Get the content of the cj2 div
    const cj2Content = document.getElementById("cj2").innerHTML;

    // Capture CSS from the original window to apply in the new window
    const styles = Array.from(document.styleSheets)
      .map((styleSheet) => {
        try {
          return Array.from(styleSheet.cssRules)
            .map((rule) => rule.cssText)
            .join("");
        } catch (error) {
          return ""; // Skip any stylesheets that can't be accessed
        }
      })
      .join("\n");

    // Write HTML to the new window's document
    if (newWindow) {
      newWindow.document.write(`
      <html>
        <head>
          <title>${title1}</title>
          <style>
            body { font: 550 15px Poppins, sans-serif; white-space: pre-wrap; margin: 0; padding: 0; }
            ${styles}
            /* Logo styling */
            #logo { display: flex; justify-content: center; margin-bottom: 15px; }
            /* Title styling */
            #title1 { font-size: 20px; margin-bottom: 20px; text-align: left; margin-left: 3px; }
            /* cj2 content styling */
            #cj2, #cj2 * { color: black !important; margin-left: 3px; 
              page-break-inside: avoid;}
          </style>
          <!-- Load html2pdf.js -->
          <script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.2/html2pdf.bundle.min.js"></script>
        </head>
        <body>
          <div id="logo">${logo}</div>
          <div id="title1">${title1}</div>
          <div id="cj2">${cj2Content}</div>
          <script>
            // Convert the content to PDF and download
            window.onload = () => {
              const element = document.body; // Capture the full body
              html2pdf()
                .set({ filename: '${title1}.pdf' })
                .from(element)
                .save();
            };
          </script>
        </body>
      </html>
    `);

      // Close the document to trigger rendering
      newWindow.document.close();
    }
  };

  // const generatePDF = () => {
  //   const logoUrl = new_logo; // Your logo image path
  //   const songName = title1; // Song name variable

  //   // Render myVariable as a string
  //   const downloadpdf_newpage = createMyVariable1(myVariable);

  //   // Open a new window for the PDF preview
  //   const newWindow = window.open('', '_blank');

  //   // Write structured HTML with inline styles for layout and formatting
  //   if (newWindow) {
  //     newWindow.document.write(`
  //       <html>
  //         <head>
  //           <style>
  //             body { font-family: Arial, sans-serif; padding: 20px; text-align: left; }
  //             .logo { text-align: center; margin-bottom: 20px; }
  //             .song-name { font-size: 18px; font-weight: bold; margin-bottom: 10px; }
  //             .content { display: flex; flex-wrap: wrap; gap: 5px; font-size: 14px; }
  //             .line {
  //               display: flex;
  //               flex-wrap: wrap;
  //               margin-bottom: 10px;
  //             }
  //             .box {
  //               font-size: 15px;
  //               display: inline-block;
  //               padding: 3px;
  //               margin-bottom: 4px;
  //               white-space: nowrap;
  //             }
  //             .box.words {
  //               color: #FFFFFF;
  //             }
  //             .box.chord { /* Assuming chord type is differentiated */
  //               color: #F0984B;
  //             }
  //             .box.editable {
  //               border: 1px solid;
  //             }
  //           </style>
  //         </head>
  //         <body>
  //           <div class="logo">
  //             <img src="${logoUrl}" alt="Logo" style="max-width: 100px;" />
  //           </div>
  //           <div class="song-name">${songName}</div>
  //           <div class="content">${downloadpdf_newpage}</div>
  //         </body>
  //       </html>
  //     `);
  //     newWindow.document.close();
  //   } else {
  //   
  //   }
  // };

  // const generatePDF = () => {
  // const doc = new jsPDF("landscape");
  // doc.setFontSize(10);
  // doc.text(title1, 10, 10);

  // const lineHeight = 8; // Reduced from 12 to minimize vertical spacing
  // const margin = 10;
  // const pageHeight = doc.internal.pageSize.height;
  // const maxLinesPerPage = Math.floor((pageHeight - 2 * margin) / lineHeight);
  // let y = margin + 10;
  // let lineIndex = 0;

  // const extractTextFromJSX = (jsxElements) => {
  //   let lines = [];
  //   let maxWordsLength = 10;

  //   React.Children.forEach(jsxElements, (lineElement) => {
  //     if (React.isValidElement(lineElement)) {
  //       let lineContent = [];
  //       let wordsInLine = 0;

  //       React.Children.forEach(lineElement.props.children, (boxElement) => {
  //         if (React.isValidElement(boxElement)) {
  //           const boxText = boxElement.props.children;
  //           // Remove any extra spaces in the text
  //           const normalizedText = boxText.replace(/\s+/g, " ").trim();
  //           const wordCount = normalizedText.split(/\s+/).length;
  //           wordsInLine += wordCount;

  //           // Calculate exact text width for precise positioning
  //           const textWidth = doc.getTextWidth(normalizedText);
  //           lineContent.push({
  //             text: normalizedText,
  //             width: textWidth // Store actual text width
  //           });
  //         }
  //       });

  //       maxWordsLength = Math.max(maxWordsLength, wordsInLine);
  //       lines.push(lineContent);
  //     }
  //   });

  //   return { lines, maxWordsLength };
  // };

  // const { lines: extractedLines, maxWordsLength } = extractTextFromJSX(myVariable);
  // const maxWidth = Math.min(15, Math.ceil(maxWordsLength));
 

  // extractedLines.forEach((line) => {
  //   let xOffset = margin;

  //   // Calculate if we need a new page before processing the line
  //   if (y + lineHeight > pageHeight - margin) {
  //     doc.addPage();
  //     y = margin + 10;
  //     lineIndex = 0;
  //   }

  //   line.forEach(({ text, width }) => {
  //     // Split text only if absolutely necessary
  //     if (width > maxWidth) {
  //       const textLines = doc.splitTextToSize(text, maxWidth);
  //       textLines.forEach((textLine, index) => {
  //         doc.text(textLine, xOffset, y + (index * lineHeight));
  //       });
  //     } else {
  //       doc.text(text, xOffset, y);
  //     }

  //     // Move x position by exact text width plus minimal spacing
  //     xOffset += maxWidth;
  //   });

  //   // Move to next line with minimal spacing
  //   y += lineHeight;
  //   lineIndex++;
  // });

  // const pdfBlob = doc.output("blob");
  // saveAs(pdfBlob, title1 + ".pdf");
  // window.print();
  //   const element = document.body;
  

  //   const lyricsDiv = document.getElementById("cj");

  //   if (lyricsDiv) {
  //     // Clone the original div so the changes do not affect the original
  //     const clonedDiv = document.createElement("div"); // New div to hold the content

  //     // Create and add logo
  //     const logo = new Image(); // More concise way to create an img element
  //     logo.src = new_logo; // Replace with the correct path to your logo
  //     logo.alt = "Logo"; // Set alt text for accessibility

  //     // Set styling for the logo
  //     logo.style.width = "100px";
  //     logo.style.display = "block";
  //     logo.style.margin = "0 auto";

  //     clonedDiv.appendChild(logo); // Add logo at the top of the cloned div

  //     // Create and add title
  //     const title = document.createElement("h2");
  //     title.textContent = title1; // Replace with your desired title
  //     title.style.color = "white"; // Set title color to white
  //     title.style.textAlign = "center"; // Center the title
  //     clonedDiv.appendChild(title); // Add title below the logo

  //     // Clone the original content and append it to the new clonedDiv
  //     const contentClone = lyricsDiv.cloneNode(true);
  //     clonedDiv.style.backgroundColor = "#282828"; // Change to your desired color

  //     clonedDiv.appendChild(contentClone); // Append the original content below the logo and title

  //     // Create a new element for PDF
  //     const pdfElement = document.createElement("div");
  //     pdfElement.appendChild(clonedDiv); // Append the newly structured div to the pdfElement

  //     // Save the PDF
  //     html2pdf()
  //       .from(pdfElement)
  //       .save(`${title1}.pdf`)
  //       .then(() => {
  //        
  //       })
  //       .catch((err) => {
  //        
  //       });
  //   } else {
  //    
  //   }
  // };
  // const generatePDF = () => {
  //   const doc = new jsPDF("landscape");
  //   doc.setFontSize(7);
  //   doc.text(title1, 10, 10);

  //   const lineHeight = 12; // Standard line height
  //   const margin = 10; // Margins from top and bottom
  //   const pageHeight = doc.internal.pageSize.height;
  //   const maxLinesPerPage = Math.floor((pageHeight - 2 * margin) / lineHeight); // Calculate max lines per page based on height
  //   let y = margin + 10; // Start y position below the title
  //   let lineIndex = 0; // Keep track of the line index

  //   // Extracts text from the JSX elements (for chords and words)
  //   const extractTextFromJSX = (jsxElements) => {
  //     let lines = [];

  //     React.Children.forEach(jsxElements, (lineElement) => {
  //       if (React.isValidElement(lineElement)) {
  //         let lineContent = [];
  //         React.Children.forEach(lineElement.props.children, (boxElement) => {
  //           if (React.isValidElement(boxElement)) {
  //             const boxText = boxElement.props.children;
  //             const normalizedText = boxText.replace(/\s+/g, " ").trim();
  //             const boxWidth = boxElement.props.width || 0;

  //             // Calculate the text width required for this box
  //             const textWidth = doc.getTextWidth(normalizedText) + 10;
  //             lineContent.push({
  //               text: normalizedText,
  //               width: Math.max(boxWidth, textWidth),
  //             });
  //           }
  //         });
  //         lines.push(lineContent);
  //       }
  //     });

  //     return lines;
  //   };

  //   const extractedLines = extractTextFromJSX(myVariable);
  //   

  //   extractedLines.forEach((line) => {
  //     let xOffset = margin; // Horizontal position (x) starts at the left margin
  //     let maxBoxHeight = 0; // To track the tallest box in this line

  //     // Set the max width for all boxes in this line
  //     const maxWidth = 16; // Adjust this width based on your layout

  //     // Process each box (chord or word) in the line
  //     line.forEach(({ text }) => {
  //       const textLines = doc.splitTextToSize(text, maxWidth - 4); // Split text to fit box
  //       const boxHeight = textLines.length * lineHeight + 4; // Calculate the height of the text block
  //       maxBoxHeight = Math.max(maxBoxHeight, boxHeight); // Track the tallest box in the current line

  //       // Check if there's enough space for the next line on the current page
  //       if (y + maxBoxHeight > pageHeight - margin) {
  //         doc.addPage(); // Add new page
  //         y = margin + 10; // Reset y-position for the new page
  //         lineIndex = 0; // Reset the line index for the new page
  //       }

  //       // Render the text inside where the box would be (box drawing removed)
  //       textLines.forEach((textLine, index) => {
  //         doc.text(textLine, xOffset + 2, y + lineHeight * (index + 1));
  //       });

  //       xOffset += maxWidth; // Move the x offset for the next box (chord or word)
  //     });

  //     // Update y for the next line (move y down by the max height of the current line)
  //     y += maxBoxHeight;
  //     lineIndex++;
  //   });

  //   const pdfBlob = doc.output("blob");
  //   saveAs(pdfBlob, title1 + ".pdf");
  // };

  // const generatePDF = () => {
  //   const doc = new jsPDF();

  //   const content = document.getElementById("pdf");

  //   html2canvas(content).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const imgWidth = 190; // Full width of A4 page
  //     const pageHeight = 275;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     let heightLeft = imgHeight;
  //     let position = 0;

  //     const addPageTitle = () => {
  //       doc.setFontSize(12); // Adjust font size as needed
  //       doc.text(title1, 10, 10); // Position and content of the title
  //     };

  //     // Add first page with title
  //     doc.addImage(imgData, "PNG", 10, 20, imgWidth, imgHeight); // Adjusted position to make space for title
  //     addPageTitle(); // Add title to the first page
  //     heightLeft -= pageHeight;

  //     // doc.addImage(imgData, "PNG", 10, 10, imgWidth, imgHeight);
  //     // heightLeft -= pageHeight;

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight;
  //       if (heightLeft > 0) {
  //         doc.addPage();
  //       }
  //       doc.addImage(imgData, "PNG", 10, position, imgWidth, imgHeight);
  //       heightLeft -= pageHeight;
  //     }

  //     const pdfBlob = doc.output("blob");
  //     const title = title1; // Replace with your desired filename
  //     saveAs(pdfBlob, title + ".pdf");
  //   });

  // };

  const handleSongCardClick = async (requestId, url) => {
    setLoading(true);
    try {
      const encodedRequestId = encodeURIComponent(requestId);
      const response_status = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response_status.status === 200) {
        // setLoading(true);

        if (response_status.data.state === "complete") {
          // navigate("/chords", { state: { result1: response_status.data } });
          visitchords(
            encodedRequestId,
            response_status.data,
            response_status.data.youtube_id
          );
          // setLoading(false);
        } else if (
          response_status.data.state === "pending" ||
          response_status.data.state === "in_progress"
        ) {
          setTimeout(() => handleSongCardClick(requestId, url), 1000);
        } else {
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      
    }
  };

  const visitchords = async (requestId, responsed, youtube_id) => {
    try {
      const response = await fetch(
        `${config.apiUrl}/playlists/__default_playlist__/${requestId}/visit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      if (response.status === 200) {
        window.scrollTo(0, 0);

        navigate(`/chords/${initials}/youtube/${youtube_id}`);
        // window.location.reload();

        // navigate("/chords", { state: { result1: responsed } });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };
  const formatChordName = (chord) => {
    let processedChord = chord
      .replace(/Major/g, "maj")
      .replace(/major/g, "maj")
      .replace(/Minor/g, "min")
      .replace(/minor/g, "min")
      .replace(/diminished/g, "dim")
      .replace(/augmented/g, "aug")
      .replace(/add/g, "add")

      .replace(/m/g, "min")
      .replace(/#/g, "__")
      .replace(/dimin/g, "dim")
      .replace(/\/[0-9]+/g, "_");

    // Handle single letter or sharp chords (e.g., E -> Emaj, C# -> C#maj)
    if (
      processedChord.length === 1 ||
      (processedChord.length === 3 && processedChord.includes("__"))
    ) {
      processedChord += "maj";
    }

    return processedChord;
  };

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "").replace(".svg", "")] = r(item);
    });

    return images;
  };

  const images = importAll(
    require.context("../../assets/cords", false, /\.svg$/)
  );
  const View_all_Songs = (event) => {
    window.scrollTo(0, 0);
    navigate("/all_songs");
  };

  const retry_chord = async () => {
    const encodedRequestId1 = encodeURIComponent(id);

    // try {
    // setLoading(true);

    // const response = await fetch(
    //   `${config.apiUrl}/url_requests/${encodedRequestId1}/retry`,
    //   {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   }
    // );

    // if (response.status === 200) {
    //   navigate("/user");
    // }

    // } catch (error) {
    //   if (error.response && error.response.status === 401) {
    //     clearToken();
    //     navigate("/");
    //   }
    // } finally {
    //   // setLoading(false);
    // }
  };

  const share_button = async () => {
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      setLoading(true);

      const response = await fetch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/share_url`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      
      if (response.status === 200) {
        const data = await response.json();
        const share_url = data.share_url;
        setshare_url1(share_url);
        setLoading(false);
        setshare_popup(true);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      setLoading(false);
    }
  };

  const handleCopyLink = () => {
    const textToCopy = url;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        showToast();
      })
      .catch((err) => {
        
      });
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  // const handleDelete = (id1) => {
  //   if (!loading) {
  //     delete_song(id1);
  //   }
  // };

  const add_playlist1 = async (playlistName) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/${playlistName}/add`,
        { records: [id] }, // Replace `id1` with your actual record ID(s)
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const textToCopy = `Added into ${playlistName} playlist`;

        // Copy text to clipboard
        navigator.clipboard
          .writeText(textToCopy)
          .then(() => {
            // Show toast after successful copy with a dynamic message
            // showToast1(textToCopy); // Pass the message to the toast
          })
          .catch((err) => {
           
          });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    }
  };

  const retry = async (id) => {
    const encodedRequestId1 = encodeURIComponent(id);
    try {
      const response = await axios.get(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/pdf`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "arraybuffer",
        }
      );

      if (response.status === 200) {
        const outputFileName = title1 + `.pdf`;
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = outputFileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
      
    }
  };

  // const edit_chord = () => {
  //   setIsEditing((prevIsEditing) => {
  //     const newIsEditing = !prevIsEditing;
  //     setImageSrc(newIsEditing ? save_edit : edit_chords);
  //     // Reset the editable state if exiting edit mode
  //     if (!newIsEditing) setEditable({ row: null, index: null });
  //     return newIsEditing;
  //   });
  // };

  const edit_chord = () => {
    if (selectedValue === "original") {
      setIsEditing(false);
      setEditable({ row: null, index: null });
      handle_save_as_original_popup();
    } else {
      setIsEditing((prevIsEditing) => !prevIsEditing);
    }
  };

  const exit_edit_mode = () => {
    setIsEditing(false);
    setEditable({ row: null, index: null });
  };

  const save_changes = async () => {
    setIsEditing(false);
    setEditable({ row: null, index: null });
    handle_save_as_original_popup();
   
  };
  
  
  

  useEffect(() => {
    if (timesArray.length > 0) {
     
    }
  }, [timesArray]);

  const processRows = () => {
    const rows = [];
    const groupedChords = {};

    // Map chords to the last index by time
    const chordTimeToIndexMap = new Map();
    chordsTs.forEach((chord, index) => {
      chordTimeToIndexMap.set(chord.time, index); // Retain last chord at each time
    });

    // Group chords by time and retain the last occurrence's original index
    chordsTs.forEach((chord, index) => {
      const chordTime = Math.round(chord.time || 0.1);
      groupedChords[chordTime] = { ...chord, originalIndex: index };
    });

    // Create rows from groupedChords
    const gp = Object.values(groupedChords).map((chord) => ({
      time: chord.time,
      value: chord.chord,
      originalIndex: chord.originalIndex,
    }));

    gp.forEach((chord) => {
      rows.push({
        type: "chord",
        time: Math.round(chord.time || 0.1),
        value: chord.value,
        originalIndex: chord.originalIndex,
      });
      // console.log(
      //   `Chord Value: ${chord.value}, Original Index: ${chord.originalIndex}`
      // );
    });

    // Process words similarly, mapping words by start time
    const wordTimeToIndexMap = new Map();
    wordsTs.forEach((word, index) => {
      wordTimeToIndexMap.set(word.start, index);
    });

    // Add words to rows
    wordsTs.forEach((word) => {
      rows.push({
        type: "word",
        time: word.start,
        value: word.word,
        originalIndex: wordTimeToIndexMap.get(word.start),
      });
    });

    // Sort rows by time
    rows.sort((a, b) => a.time - b.time);

    // Group chords and words in finalRows
    let currentChordsRow = [];
    let currentWordsRow = [];
    let currentWordIndices = [];
    let finalRows = [];
    let previousChord = null;
    const extractedTimes = [];

    wordsTs.forEach((wordObj) => {
      if (wordObj.word.includes("\n")) {
        extractedTimes.push(Math.round(wordObj.end));
      }
    });
    settimesArray(extractedTimes);

    rows.forEach((row) => {
      if (row.type === "chord") {
        if (previousChord !== row.value) {
          currentChordsRow.push(row.value);
          currentWordsRow.push("");
          currentWordIndices.push(row.originalIndex);
          previousChord = row.value;
        }
      } else if (row.type === "word") {
        if (row.value.includes("\n")) {
          const wordParts = row.value.split("\n");

          // Handle first part of split word
          currentWordsRow.push(wordParts[0]);
          currentWordIndices.push(row.originalIndex); // Maintain the index for the first part
          currentChordsRow.push("");

          finalRows.push({
            type: "chords",
            row: currentChordsRow,
            originalIndices: currentWordIndices, // Directly use currentWordIndices here
          });
          finalRows.push({
            type: "words",
            row: currentWordsRow,
            originalIndices: currentWordIndices,
          });

          // Reset rows for new line
          currentChordsRow = [];
          currentWordsRow = [];
          currentWordIndices = [];
          previousChord = null;

          // Second part of the split word, if exists
          if (wordParts[1]) {
            currentWordsRow.push(wordParts[1]);
            currentWordIndices.push(row.originalIndex); // Maintain the same index for the second part
            currentChordsRow.push("");
          }
        } else {
          currentWordsRow.push(row.value);
          currentWordIndices.push(row.originalIndex);
          currentChordsRow.push("");
        }
      }
    });

    // Add remaining rows
    if (currentChordsRow.length > 0 || currentWordsRow.length > 0) {
      finalRows.push({
        type: "chords",
        row: currentChordsRow,
        originalIndices: currentWordIndices, // Use currentWordIndices directly
      });
      finalRows.push({
        type: "words",
        row: currentWordsRow,
        originalIndices: currentWordIndices,
      });
    }

    // finalRows.forEach((rowData, index) => {
    //   console.log(`Row Type: ${rowData.type}`);
    //   console.log(`Row ${index + 1}:`, rowData.row);
    //   console.log(`Original Indices ${index + 1}:`, rowData.originalIndices);
    // });
    setFinalRows(finalRows);
    return finalRows;
  };

  const calculateWidth = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "15px Arial"; // Set the font size and style to match your UI
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const sharpToFlatRootMap = {
    "C#": "Db",
    "D#": "Eb",
    "F#": "Gb",
    "G#": "Ab",
    "A#": "Bb",
  };

  const convertSharpToFlat = (chord) => {
    const root = chord.slice(0, 2);
    const rest = chord.slice(2);

    const flatRoot = sharpToFlatRootMap[root] || root;

    return flatRoot + rest;
  };

  const flatToSharpRootMap = {
    Db: "C#",
    Eb: "D#",
    Gb: "F#",
    Ab: "G#",
    Bb: "A#",
  };

  // const handleChordUpdate = (rowIndex, itemIndex, newChord) => {
  //   const currentRow = combinedRowsWithMaxWidths[rowIndex];

  //   if (currentRow && currentRow.type === 'chords') {
  //     const originalIndex = currentRow.originalIndices[itemIndex];

  //     if (originalIndex !== null && originalIndex < chordsTs.length) {
  //       const updatedChordsTs = [...chordsTs];
  //       updatedChordsTs[originalIndex] = {
  //         ...updatedChordsTs[originalIndex],
  //         chord: newChord
  //       };
  //       setchordsTs(updatedChordsTs);
  //     } else {
  //      
  //     }
  //   }
  // };

  const handleChordUpdate = (index, newChord) => {
    // console.log("Updating chords at index:", index, "with:", newChord);
    const updatedChordsTs = [...chordsTs];
    updatedChordsTs[index] = {
      ...updatedChordsTs[index],
      chord: newChord,
    };
    // console.log("updatedChordsTs:", updatedChordsTs);
    setchordsTs(updatedChordsTs);
    submit_chords_and_words1(updatedChordsTs);
  };

  const handleLyricsUpdate = (index, newLyrics) => {
    let newword;
    if(lastitem!==""){
       newword = newLyrics +"\n";
      
    }else{
       newword = newLyrics;
      
    }
    const updatedWordsTs = [...wordsTs];
    updatedWordsTs[index] = {
      ...updatedWordsTs[index],
      word: newword,
    };
    // console.log("updatedWordsTs:", updatedWordsTs);
    setwordsTs(updatedWordsTs);
    submit_chords_and_words(updatedWordsTs);
  };

 

  const handleBlur1 = async (rowIndex, itemIndex, event) => {
    if (!isEditing) return;
  
    setrowIndex(rowIndex);
    setitemIndex(itemIndex);
  
    const finalRow = finalRows[rowIndex];
  
    // console.log("Row Index:", rowIndex);
    // console.log("Item Index:", itemIndex);
    // console.log("Final Row:", finalRow);
    // console.log("Original Indices in Final Row:", finalRow.originalIndices);
  
    const originalIndex = finalRow.originalIndices[itemIndex];
    const isLastItem = itemIndex === finalRow.row.length - 1;
  
    if (finalRow.type === "chords") {
      const displayedChord = finalRow.row[itemIndex];
      // console.log("Displayed Chord:", displayedChord);
      // console.log("Original Index for Chord:", originalIndex);
      // console.log("Is Last Item:", isLastItem);
  
      setdisplayedWord(displayedChord);
      setwordIndex(originalIndex);
      setupdatedText("chords");
  
      if (displayedChord !== "") {
        handleyouClick_edittext();
      }
    } else if (finalRow.type === "words") {
      const displayedWord = finalRow.row[itemIndex];
      // console.log("Displayed Word:", displayedWord);
      // console.log("Original Index for Word:", originalIndex);
      // console.log("Is Last Item:", isLastItem);
      // console.log("wordstds:", wordsTs);
  
      setdisplayedWord(displayedWord);
      setwordIndex(originalIndex);
      setupdatedText("words");
      if (isLastItem) {
       setlastitem("yes");
      }else{
        setlastitem("");
      }
  
      if (displayedWord !== "") {
        handleyouClick_edittext();
      }
    }
  
    // Optionally, you could add additional actions if it's the last item:
   
  };
  
  

  const createMyVariable = (rows) => {
    return rows.map((row, rowIndex) => (
      <Line key={rowIndex} type={row.type}>
        {row.row.length === 0 || !row.row.some(Boolean) ? (
          <Box
            key={`empty-${rowIndex}`}
            type={row.type}
            width={row.widths[0] || 0}
            editMode={isEditing}
            isGrouped={row.grouped}
            style={{ height: "20px", fontSize: `${fontSize}px` }}
          />
        ) : (
          row.row.map((item, itemIndex) => (
            <Box
              key={itemIndex}
              type={row.type}
              width={row.widths[itemIndex]}
              editMode={isEditing}
              isGrouped={row.grouped}
              style={{
                fontSize: `${fontSize}px`,
                cursor: isEditing ? "text" : "default",
              }}
              // contentEditable={isEditing} // Make all boxes editable when in edit mode
              // suppressContentEditableWarning={true}
              // onBlur={(event) => handleBlur(rowIndex, itemIndex, event)}
              onClick={(event) => handleBlur1(rowIndex, itemIndex, event)}
            >
              {item}
            </Box>
          ))
        )}
      </Line>
    ));
  };

  const submit_chords_and_words = async (updatedWordsTs) => {
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.patch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
        {
          chords_ts: chordsTs,
          words_ts_newline: updatedWordsTs,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        handleyouClick_edittext1();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } else if (error.response && error.response.status === 400) {
        // setErrormsg(error.response.data.message); // Display the actual error message
      } else {
        // setErrormsg("An unknown error occurred"); // Handle any other errors
      }
    }
  };

  const submit_chords_and_words1 = async (updatedChordsTs) => {
    const encodedRequestId1 = encodeURIComponent(id);

    try {
      const response = await axios.patch(
        `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
        {
          chords_ts: updatedChordsTs,
          words_ts_newline: wordsTs,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        handleyouClick_edittext1();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      } else if (error.response && error.response.status === 400) {
        // setErrormsg(error.response.data.message); // Display the actual error message
      } else {
        // setErrormsg("An unknown error occurred"); // Handle any other errors
      }
    }
  };

  const sharp_to_flat = () => {
    const updatedRows = combinedRowsWithMaxWidths.map((row) => {
      if (row.type === "chords") {
        const updatedChords = row.row.map((chord) => {
          const rootNote = Object.keys(sharpToFlatRootMap).find((sharp) =>
            chord.startsWith(sharp)
          );
          if (rootNote) {
            return chord.replace(rootNote, sharpToFlatRootMap[rootNote]);
          }
          return chord;
        });

        return { ...row, row: updatedChords };
      }
      return row;
    });

    setCombinedRowsWithMaxWidths(updatedRows);
    setMyVariable(createMyVariable(updatedRows));
  };

  const flat_to_sharp = () => {
    const updatedRows = combinedRowsWithMaxWidths.map((row) => {
      if (row.type === "chords") {
        const updatedChords = row.row.map((chord) => {
          const rootNote = Object.keys(flatToSharpRootMap).find((flat) =>
            chord.startsWith(flat)
          );
          if (rootNote) {
            return chord.replace(rootNote, flatToSharpRootMap[rootNote]);
          }
          return chord;
        });

        return { ...row, row: updatedChords };
      }
      return row;
    });

    setCombinedRowsWithMaxWidths(updatedRows);
    setMyVariable(createMyVariable(updatedRows));
  };

  useEffect(() => {
    const combinedRows = processRows();
    const combinedRowsWithMaxWidths = [];
    let tempChordsRow = [];
    let isGroupedChords = false;

    let originalChordIndex = 0;
    let originalWordIndex = 0;

    combinedRows.forEach((row, index, array) => {
      if (row.type === "chords") {
        const nextRow = array[index + 1];
        const isNextChordsWithoutWords =
          nextRow &&
          nextRow.type === "chords" &&
          (!array[index + 2] || array[index + 2].type !== "words");

        if (!row.row.some(Boolean)) {
          combinedRowsWithMaxWidths.push({
            type: "chords",
            row: [""], // Add an empty string to signify an empty row
            widths: [0], // Optional: Set a width of 0 for the empty row
            grouped: false,
            originalIndices: [originalChordIndex],
          });
          originalChordIndex++;
          return; // Skip to the next iteration
        }

        tempChordsRow = [...tempChordsRow, ...row.row];

        if (isNextChordsWithoutWords) {
          isGroupedChords = true;
        } else {
          const combinedWidths = tempChordsRow.map((chord) =>
            calculateWidth(chord)
          );

          combinedRowsWithMaxWidths.push({
            type: "chords",
            row: tempChordsRow,
            widths: combinedWidths,
            grouped: isGroupedChords,
            originalIndices: tempChordsRow.map(() => originalChordIndex++),
          });

          tempChordsRow = [];
          isGroupedChords = false;
        }
      } else if (row.type === "words") {
        const wordWidths = row.row.map((word) => calculateWidth(word));

        const prevRow =
          combinedRowsWithMaxWidths[combinedRowsWithMaxWidths.length - 1];
        if (prevRow && prevRow.type === "chords") {
          const chordWidths = prevRow.row.map((chord) => calculateWidth(chord));
          const maxWidths = wordWidths.map((wordWidth, i) =>
            Math.max(wordWidth, chordWidths[i] || 0)
          );
          prevRow.widths = maxWidths;
          row.widths = maxWidths;
        } else {
          row.widths = wordWidths;
        }

        // row.originalIndices = row.row.map(() => originalWordIndex++);
        combinedRowsWithMaxWidths.push(row);
      }
    });

    setCombinedRowsWithMaxWidths(combinedRowsWithMaxWidths);
    setMyVariable(createMyVariable(combinedRowsWithMaxWidths));
  }, [chordsTs, wordsTs, isEditing, fontSize]);

  useEffect(() => {
    // Whenever fontSize changes, recreate myVariable with the updated font size.
    setMyVariable(createMyVariable(combinedRowsWithMaxWidths));
  }, [fontSize, combinedRowsWithMaxWidths]);

  useEffect(() => {
    const allChords = combinedRowsWithMaxWidths
      .filter((row) => row.type === "chords")
      .flatMap((row) => row.row);
    const uniqueChordSet = new Set(allChords);
    //  setUniqueChords(Array.from(uniqueChordSet));
  }, [combinedRowsWithMaxWidths]); // Include isEditing in dependency array

  downlines.push(myVariable);

  // const submit_chords_and_words = async () => {
  //   const encodedRequestId1 = encodeURIComponent(id);

  //   try {
  //     const response = await axios.patch(
  //       `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${selectedValue}`,
  //       {
  //       chords_ts: chordsTs,
  //       words_ts_newline: wordsTs
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (response.status === 200) {

  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 401) {
  //       clearToken();
  //       navigate("/");
  //     } else if (error.response && error.response.status === 400) {
  //       // setErrormsg(error.response.data.message); // Display the actual error message
  //     } else {
  //       // setErrormsg("An unknown error occurred"); // Handle any other errors
  //     }
  //   }
  // };

  // downlines.push(myVariable);

  const downloadpdf_newpage = myVariable;

  const sharpScale = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  const flatScale = [
    "C",
    "Db",
    "D",
    "Eb",
    "E",
    "F",
    "Gb",
    "G",
    "Ab",
    "A",
    "Bb",
    "B",
  ];

  // Function to transpose a single chord by semitones (+1 or -1)
  function transposeChord(chord, semitones) {
    // Extract root note (C, D#, Bb, etc.) and suffix (maj7, m, dim, etc.)
    const regex = /^([A-G][b#]?)(.*)$/; // Match root note (A-G with optional # or b) and suffix
    const matches = chord.match(regex);

    if (!matches) {
      return chord; // Return original chord if it doesn't match the regex
    }

    const root = matches[1]; // Root note
    const suffix = matches[2]; // Chord suffix (e.g., m7, maj, dim, etc.)

    // Determine whether to use sharp or flat scale
    let scale;
    if (sharpScale.includes(root)) {
      scale = sharpScale;
    } else if (flatScale.includes(root)) {
      scale = flatScale;
    } else {
      return chord; // If root is not found, return the original chord
    }

    // Find the root note's index in the scale and transpose it
    let index = scale.indexOf(root);
    let newIndex = (index + semitones + scale.length) % scale.length;

    // Return the transposed chord (transposed root + original suffix)
    return scale[newIndex] + suffix;
  }

  // Function to transpose all chords in the chord sequence
  function transposeChords(chords, semitones) {
    return chords.map((chordData) => ({
      time: chordData.time,
      chord: transposeChord(chordData.chord, semitones),
    }));
  }

  function transposeChord1(chord, semitones) {
    const regex = /^([A-G][b#]?)(.*)$/; // Match root note and suffix
    const matches = chord.match(regex);

    if (!matches) {
      return chord; // Return original chord if it doesn't match
    }

    const root = matches[1]; // Root note
    const suffix = matches[2]; // Chord suffix

    // Determine whether to use sharp or flat scale
    let scale;
    if (sharpScale.includes(root)) {
      scale = sharpScale;
    } else if (flatScale.includes(root)) {
      scale = flatScale;
    } else {
      return chord; // If root is not found, return original chord
    }

    // Find the root note's index and transpose
    let index = scale.indexOf(root);
    let newIndex = (index + semitones + scale.length) % scale.length;

    // Return transposed chord
    return scale[newIndex] + suffix;
  }

  // Function to transpose all chords in the array
  function transposeChordsArray1(chords, semitones) {
    return chords.map((chord) => transposeChord(chord, semitones));
  }

  const minus_one1 = async () => {
    let transposedUp = transposeChords(chordsTs, -1);
    let transup = transposeChordsArray1(uniqueChords, -1);

    setUniqueChords(transup);
    setchordsTs(transposedUp);
  };

  const plus_one1 = async () => {
    let transposedDown = transposeChords(chordsTs, 1);
    let transdown = transposeChordsArray1(uniqueChords, 1);
    setUniqueChords(transdown);
    setchordsTs(transposedDown);
  };

  const transposeOptions = [
    { label: "- 1", value: -1 },
    { label: "+ 1", value: 1 },
  ];

  // const handleClick = async (value) => {
  //   // Calculate the new transposed value
  //   const newTransposedValue = transposedValue + value;

  //   // Update the transposition value state
  //   setTransposedValue(newTransposedValue);

  //   // Update the active button state
  //   setActiveButton(value);

  //   // Call the appropriate method based on button clicked
  //   if (value === -1) {
  //     await minus_one2(newTransposedValue);
  //   } else if (value === 1) {
  //     await plus_one2(newTransposedValue);
  //   }
  // };

  const handleClick = async (value) => {
    let newTransposedValue;

    if (value === 1) {
      newTransposedValue = (transposedValue + value) % 12;
    } else if (value === -1) {
      newTransposedValue = transposedValue - 1;
      if (newTransposedValue < -11) {
        newTransposedValue = 0;
      }
    }

    setTransposedValue(newTransposedValue);

    setActiveButton(value);

    if (value === -1) {
      await minus_one2(newTransposedValue);
    } else if (value === 1) {
      await plus_one2(newTransposedValue);
    }
  };

  const minus_one2 = async (newTransposedValue) => {
    let transposedUp = transposeChords(chordsTs, -1);
    let transup = transposeChordsArray1(uniqueChords, -1);

    setUniqueChords(transup);
    setchordsTs(transposedUp);
  };

  const plus_one2 = async (newTransposedValue) => {
    let transposedDown = transposeChords(chordsTs, 1);
    let transdown = transposeChordsArray1(uniqueChords, 1);

    setUniqueChords(transdown);
    setchordsTs(transposedDown);
  };

  const [position, setPosition] = useState({
    x: window.innerWidth / 2 - 100, // Center horizontally
    y: window.innerHeight - 100, // 100px from the bottom
  });

  const handleMouseDown = (e) => {
    if (!isMobile) {
      setDragging(true);
      setOffset({
        x: e.clientX - position.x,
        y: e.clientY - position.y,
      });
    }
  };

  const handleMouseMove = (e) => {
    if (!dragging) return;
    setPosition({
      x: e.clientX - offset.x,
      y: e.clientY - offset.y,
    });
  };

  const handleMouseUp = () => {
    if (!isMobile) {
      setDragging(false);
    }
  };

  // const checkContainer = () => {
  //   const container = document.getElementById("cj");
  //   if (container) {
  //     console.log("Container found:", {
  //       height: container.clientHeight,
  //       scrollHeight: container.scrollHeight,
  //       overflow: window.getComputedStyle(container).overflow,
  //     });
  //     return container;
  //   }
  //   console.log("Container not found");
  //   return null;
  // };

  useEffect(() => {
    let timer;

    // Auto-scrolling logic
    if (isActive) {
      timer = setInterval(() => {
        setElapsedTime((prev) => {
          const newTime = prev + 1;

          // Calculate and log time information
          if (currentIndex < timesArray.length) {
            const nextScrollTime = timesArray[currentIndex];
            const timeUntilNextScroll = nextScrollTime - newTime;

           
          }

          // Check if we need to scroll at this time
          if (
            currentIndex < timesArray.length &&
            newTime >= timesArray[currentIndex]
          ) {
            try {
              // Scroll incrementally
              const newPosition = window.pageYOffset + 70;

              // Smooth scroll
              window.scrollTo({
                top: newPosition,
                behavior: "smooth",
              });

             
              setScrollPosition(newPosition);
              setCurrentIndex((prev) => prev + 1);
            } catch (error) {
              
            }
          }

          return newTime;
        });
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [isActive, currentIndex, scrollPosition]);

  // Handle button click
  const handleToggle = () => {
    if (!isActive) {
      // Scroll to 'cj' div first
      const element = document.getElementById("cj1");
      if (element) {
        const rect = element.getBoundingClientRect();
        const elementTop = rect.top + window.pageYOffset;

        // Scroll to the 'cj' div smoothly
        window.scrollTo({
          top: elementTop - 100,
          behavior: "smooth",
        });

       
        // Start the scroll sequence after scrolling to 'cj'
        setTimeout(() => {
          setCurrentIndex(0);
          setElapsedTime(0);
          setScrollPosition(window.pageYOffset);
          setIsActive(true);
         
        }, 500); // 500ms delay to allow scroll to complete
      }
    } else {
     
      setIsActive(false);
    }
  };

  const EditTextForm = ({ onClose }) => {
    const [edt_text, setedt_text] = useState(displayedWord); // Set initial value from displayedWord
    const [edt_text1, setedt_text1] = useState(""); 
    const [errormsg, setErrormsg] = useState("");

    const handleCreateClick = (event) => {
      event.preventDefault();

      const currentRow = combinedRowsWithMaxWidths[rowIndex];

      if (currentRow.type === "chords") {
        const index = wordIndex;

        // console.log("wordIndex ", index);
        // console.log("updatedText ", edt_text1);
        handleChordUpdate(index, edt_text1);
      } else if (currentRow.type === "words") {
        const index = wordIndex;
        // console.log("wordIndex ", index);
        // console.log("updatedText ", edt_text1); // Use edt_text directly here
        handleLyricsUpdate(index, edt_text1);
      }
    };

    return (
      updatedText === "words" ? (
        <FormContainer>
          <Form>
            <HeaderContainer>
              <Title01>Edit Lyrics</Title01>
              <CloseButton onClick={onClose}>×</CloseButton>
            </HeaderContainer>
  
            <InputContainer>
              <Label htmlFor="playlistTitle">Original Lyrics</Label>
              <InputWrapper>
                <Input value={edt_text} readOnly disabled />
              </InputWrapper>
            </InputContainer>
  
            <InputContainer>
              <Label htmlFor="playlistTitle">New Lyrics</Label>
              <InputWrapper>
                <Input
                  id="VersionTitle"
                  type="text"
                  aria-label="Enter Version title"
                  placeholder="Enter New Lyrics"
                  onChange={(e) => setedt_text1(e.target.value)}
                />
              </InputWrapper>
              {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}
            </InputContainer>
  
            <ButtonContainer01>
              <Button01 onClick={handleCreateClick}>Update Lyrics</Button01>
            </ButtonContainer01>
          </Form>
        </FormContainer>
      ) : updatedText === "chords" ? (
        <FormContainer>
          <Form>
            <HeaderContainer>
              <Title01>Edit Chords</Title01>
              <CloseButton onClick={onClose}>×</CloseButton>
            </HeaderContainer>
  
            <InputContainer>
              <Label htmlFor="playlistTitle">Original Chords</Label>
              <InputWrapper>
                <Input value={edt_text} readOnly disabled />
              </InputWrapper>
            </InputContainer>
  
            <InputContainer>
              <Label htmlFor="playlistTitle">New Chords</Label>
              <InputWrapper>
                <Input
                  id="ChordTitle"
                  type="text"
                  aria-label="Enter Chord title"
                  placeholder="Enter New Chords"
                  onChange={(e) => setedt_text1(e.target.value)}
                />
              </InputWrapper>
              {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}
            </InputContainer>
  
            <ButtonContainer01>
              <Button01 onClick={handleCreateClick}>Update Chords</Button01>
            </ButtonContainer01>
          </Form>
        </FormContainer>
      ) : null
    );
  };

  const NewVersionForm = ({ onClose, id }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState(""); // Only using errormsg for error display

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg(""); // Clear previous errors
        add_retry_version(versionTitle);
      }
    };

    const add_retry_version = async (title) => {
      const encodedRequestId1 = encodeURIComponent(id);

      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/retry_variant`,
          {
            name: title,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);
          // get_variant(title);
          const ver = "Generating New Version";
          const title01 = title + " - " + ver; // Concatenate using +
          showToast5(title01);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Enter Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle}
                onChange={(e) => setversionTitle(e.target.value)}
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}{" "}
            {/* Show error message only if present */}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };



  const SaveAsForm = ({ onClose}) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState("");
 

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg(""); // Clear previous errors
        add_retry_version(versionTitle);
      }
    };

    const add_retry_version = async (title) => {
      const encodedRequestId1 = encodeURIComponent(id);

      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants`,
          {
            "name": title,
            "words_ts_formatted": [],
  "chords_ts_formatted": [],
  "words_ts_newline": wordsTs,
  "chords_ts": chordsTs
            
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);
          // get_variant(title);
          const ver = "Generating New Version";
          const title01 = title + " - " + ver; // Concatenate using +
          showToast5(title01);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Enter Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="playlistTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle}
                onChange={(e) => setversionTitle(e.target.value)}
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}{" "}
            {/* Show error message only if present */}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };



  const DeleteVersionForm = ({ onClose, id, name }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setversionTitle] = useState("");
    const [errormsg, setErrormsg] = useState(""); // Only using errormsg for error display

    const delete_version = async (e) => {
      e.preventDefault();
      const encodedRequestId1 = encodeURIComponent(id);
      try {
        const response = await axios.delete(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);

          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message); // Display the actual error message
        } else {
          setErrormsg("An unknown error occurred"); // Handle any other errors
        }
      }
    };

    const ActionButton = ({ text, primary, onClick }) => {
      return (
        <Button4 primary={primary} onClick={onClick}>
          {text}
        </Button4>
      );
    };

    return (
      <FormContainer4>
        <Form>
          <HeaderContainer4>
            <Title01>Warning</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer4>

          <Content4>
            Are you sure you want to delete this version? This action cannot be
            undone, and all data associated with this version will be
            permanently lost.
          </Content4>
          <ActionButton
            text="Delete"
            primary
            onClick={(e) => delete_version(e)}
          />

          <ActionButton text="Cancel" onClick={onClose} />

          {/* <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Create</Button01>
          </ButtonContainer01> */}
        </Form>
      </FormContainer4>
    );
  };

  const EditVersionForm = ({ onClose, id, name }) => {
    const navigate = useNavigate();
    const token = getToken();

    const [versionTitle, setVersionTitle] = useState(name); // Initialize with name
    const [errormsg, setErrormsg] = useState("");

    useEffect(() => {
      setVersionTitle(name); // Set versionTitle to the existing name when the component mounts
    }, [name]);

    const handleCreateClick = (event) => {
      event.preventDefault();

      if (versionTitle.trim() === "") {
        setErrormsg("Please enter a title");
      } else {
        setErrormsg("");
        edit_name(versionTitle);
      }
    };

    const edit_name = async (edt_name) => {
      const encodedRequestId1 = encodeURIComponent(id);
      try {
        const response = await axios.post(
          `${config.apiUrl}/url_requests/${encodedRequestId1}/variants/${name}/rename`,
          {
            name: edt_name,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          get_retry_version(id);
          onClose();
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          clearToken();
          navigate("/");
        } else if (error.response && error.response.status === 400) {
          setErrormsg(error.response.data.message);
        } else {
          setErrormsg("An unknown error occurred");
        }
      }
    };

    return (
      <FormContainer>
        <Form>
          <HeaderContainer>
            <Title01>Edit Version name</Title01>
            <CloseButton onClick={onClose}>×</CloseButton>
          </HeaderContainer>

          <InputContainer>
            <Label htmlFor="versionTitle">Title</Label>
            <InputWrapper>
              <Input
                id="VersionTitle"
                type="text"
                placeholder="Version title"
                aria-label="Enter Version title"
                value={versionTitle} // Use versionTitle as the value
                onChange={(e) => setVersionTitle(e.target.value)} // Update versionTitle on change
              />
            </InputWrapper>
            {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>}
          </InputContainer>

          <ButtonContainer01>
            <Button01 onClick={handleCreateClick}>Confirm</Button01>
          </ButtonContainer01>
        </Form>
      </FormContainer>
    );
  };

  return (
    <>
      {/* <Screen> */}
      <Pre_nav />
      <Overlay loading={loading}>
        <Spinner />
        {/* <LoadingText>Loading... Please wait</LoadingText> */}
      </Overlay>
      <AppContainer1>
        {isMobile ? (
          <Con1>
            <Con2>
              <Con3>
                <Header51>Transpose : {transposedValue}</Header51>
                <ButtonContainer1>
                  {transposeOptions.map((option) => (
                    <TransposeButton1
                      key={option.value}
                      isActive={activeButton === option.value}
                      onClick={() => handleClick(option.value)}
                    >
                      {option.label}
                    </TransposeButton1>
                  ))}
                </ButtonContainer1>
              </Con3>

              <Con4>
                <DropdownContainer>
                  <Select
                    id="tempo"
                    value={
                      selectedOption === "Scroll: Manual"
                        ? "Manual"
                        : "Auto Tempo"
                    }
                    onChange={handleSelectChange}
                    className="w-full opacity-0"
                  >
                    <option value="Auto Tempo">Scroll : Auto Tempo</option>
                    <option value="Manual">Scroll : Manual</option>
                  </Select>
                </DropdownContainer>

                {selectedOption === "Scroll: Auto Tempo" ? (
                  <Header61 onClick={handleToggle} isActive={isActive}>
                    {isActive ? "Stop" : "Start"} {/* Toggle text */}
                  </Header61>
                ) : (
                  <>
                    <SpeedControl>
                      <SpeedButton onClick={decreaseScrollSpeed}>
                        &lt;
                      </SpeedButton>
                      <Header7>{scrollSpeed}</Header7>
                      <SpeedButton onClick={increaseScrollSpeed}>
                        &gt;
                      </SpeedButton>
                    </SpeedControl>
                  </>
                )}
              </Con4>
            </Con2>
          </Con1>
        ) : (
          <Con
            style={{
              left: `${position.x}px`,
              top: `${position.y}px`,
              position: "fixed",
              cursor: dragging ? "grabbing" : "grab",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {/* Other elements inside Con */}
            <Header5>Transpose: {transposedValue}</Header5>
            <ButtonContainer>
              {transposeOptions.map((option) => (
                <TransposeButton
                  key={option.value}
                  isActive={activeButton === option.value}
                  onClick={() => handleClick(option.value)}
                >
                  {option.label}
                </TransposeButton>
              ))}
            </ButtonContainer>

            {/* <Header6>Auto Tempo</Header6> */}

            <DropdownContainer>
              <Select
                id="tempo"
                value={
                  selectedOption === "Scroll: Manual" ? "Manual" : "Auto Tempo"
                }
                onChange={handleSelectChange}
                className="w-full opacity-0"
              >
                <option value="Auto Tempo">Scroll : Auto Tempo</option>
                <option value="Manual">Scroll : Manual</option>
              </Select>
            </DropdownContainer>

            {selectedOption === "Scroll: Auto Tempo" ? (
              <Header61 onClick={handleToggle} isActive={isActive}>
                {isActive ? "Stop" : "Start"} {/* Toggle text */}
              </Header61>
            ) : (
              <>
                <SpeedControl>
                  <SpeedButton onClick={decreaseScrollSpeed}>&lt;</SpeedButton>
                  <Header7>{scrollSpeed}</Header7>
                  <SpeedButton onClick={increaseScrollSpeed}>&gt;</SpeedButton>
                </SpeedControl>
              </>
            )}

            {/* <Header6 >Auto Scroll: </Header6>
            <Header7>{scrollSpeed}</Header7>
            <SpeedControl>
              <SpeedButton onClick={decreaseScrollSpeed}>&lt;</SpeedButton>
              <SpeedButton onClick={increaseScrollSpeed}>&gt;</SpeedButton>
            </SpeedControl>

            <Header61 onClick={handleToggle}>Scroll by Song Speed</Header61> */}

            {/* <Header6>Font Size: </Header6>
            <FontSizeControls>
            <FontSizeButton1  isActive={activeButton1 === 'decrease'} onClick={decreaseFontSize}>-Aa</FontSizeButton1>
            <FontSizeButton1 isActive={activeButton === 'increase'} onClick={increaseFontSize}>+Aa</FontSizeButton1>
            </FontSizeControls> */}
          </Con>
        )}

        {isMobile ? (
          <SongSection>
            {/* <Button_back1 src={back_arraow_chords} onClick={Go_back()}/> */}
            <Songs1>
              <Button_back1 src={back_arraow_chords} onClick={Go_back} />

              <AlbumCover src={thumb_img} alt="Album cover" />

              <SongTitle>{title1}</SongTitle>
            </Songs1>
            <SongInfo3>
              <SongActions2>
                <DownloadButton5 onClick={handleyouClick_playlist}>
                  <ButtonImage1 src={add_to_playlisted} />
                </DownloadButton5>
                {subscription1 == null ? (
                  <StyledDownloadButton>
                    <ButtonImage0 src={download_free} />
                    <Tooltip>
                      Upgrade to premium to <br></br> download the pdf <br></br>
                    </Tooltip>
                    <Imagearrow src={arrow} alt="Arrow" />
                  </StyledDownloadButton>
                ) : (
                  <DownloadButton onClick={generatePDF}>
                    <ButtonImage src={download_blue} />
                  </DownloadButton>
                )}
                <DownloadButton1 onClick={handleCopyLink}>
                  <ButtonImage1 src={copy_url} />
                </DownloadButton1>
              </SongActions2>

              <SongActions1>
                <DownloadButton4 onClick={handleyouClick_retryversion}>
                  <ButtonImage src={retry_chords} />
                </DownloadButton4>

                <DownloadButton3 onClick={downgrade1}>
                  <ButtonImage src={delete_button} />
                </DownloadButton3>
                <DownloadButton10 onClick={share_button}>
                  <ButtonImage src={share_btn} />
                </DownloadButton10>
              </SongActions1>
            </SongInfo3>
          </SongSection>
        ) : (
          <SongSection>
            <Button_back src={back_arraow_chords} onClick={Go_back} />
            <AlbumCover src={thumb_img} alt="Album cover" />
            <SongInfo>
              <SongTitle>{title1}</SongTitle>

              <SongActions>
                <DownloadButton5 onClick={handleyouClick_playlist}>
                  <ButtonImage1 src={add_to_playlisted} />
                </DownloadButton5>
                {subscription1 == null ? (
                  <StyledDownloadButton>
                    <ButtonImage0 src={download_free} alt="Download Free" />
                    <Tooltip>
                      Upgrade to premium to <br /> download the pdf <br />
                    </Tooltip>
                    <Imagearrow src={arrow} alt="Arrow" />
                  </StyledDownloadButton>
                ) : (
                  <DownloadButton onClick={generatePDF}>
                    <ButtonImage src={download_blue} />
                  </DownloadButton>
                )}

                <DownloadButton1 onClick={handleCopyLink}>
                  <ButtonImage1 src={copy_url} />
                </DownloadButton1>
                {/* <DownloadButton4 onClick={retry_chord}>
                  <ButtonImage src={retry_chords} />
                </DownloadButton4> */}
                <DownloadButton4 onClick={handleyouClick_retryversion}>
                  <ButtonImage src={retry_chords} />
                </DownloadButton4>
                <DownloadButton2 onClick={downgrade1}>
                  <ButtonImage src={delete_button} />
                </DownloadButton2>
                <DownloadButton10 onClick={share_button}>
                  <ButtonImage src={share_btn} />
                </DownloadButton10>
              </SongActions>
              <Header8>
                Not quite right? Click 'Retry' for a fresh take on chords and
                lyrics. It's quick and easy to get a better match!
              </Header8>
            </SongInfo>
          </SongSection>
        )}

        {PopupVisible && (
          <PopupOverlay>
            <CreatePlaylistPopup onClose={closepopup} />
          </PopupOverlay>
        )}
        {down && (
          <PopupOverlay>
            <Downgrade
              onClose={() => setDown(false)}
              id1={id}
              hiddenParam={hiddenParam}
            />
          </PopupOverlay>
        )}
        {showDropdown && (
          <DropdownMenu>
            {playlists.map((playlist, index) => (
              <MenuItem
                key={index}
                onClick={() => add_playlist1(playlist.name)}
              >
                {playlist.name}
              </MenuItem>
            ))}
          </DropdownMenu>
        )}

        {showversion_title_popup && (
          <PopupOverlay>
            <NewVersionForm onClose={handleyouClick_retryversion1} id={id} />
          </PopupOverlay>
        )}


{saveas_original && (
          <PopupOverlay>
            <SaveAsForm onClose={handle_save_as_original_popup1}  />
          </PopupOverlay>
        )}

        {showedt_txt_popup && (
          <PopupOverlay>
            <EditTextForm onClose={handleyouClick_edittext1} />
          </PopupOverlay>
        )}

        {showversion_del_popup && (
          <PopupOverlay>
            <DeleteVersionForm
              onClose={handleDelete_close}
              id={id}
              name={del_name}
            />
          </PopupOverlay>
        )}

        {showversion_edit_popup && (
          <PopupOverlay>
            <EditVersionForm
              onClose={handleEdit_close}
              id={id}
              name={edt_name}
            />
          </PopupOverlay>
        )}

        {showplaylist_popup && (
          <PopupOverlay>
            <PlaylistForm onClose={handleyouClick_playlist1} id1={id} />
          </PopupOverlay>
        )}

        {show_feedback && (
          <>
            {isMobile ? (
              <PopupOverlay3>
                <FeedbackPopup onClose={() => setshow_feedback(false)} />
              </PopupOverlay3>
            ) : (
              <PopupOverlay1>
                <FeedbackPopup onClose={() => setshow_feedback(false)} />
              </PopupOverlay1>
            )}
          </>
        )}

        {share_popup && (
          <PopupOverlay>
            <ShareLinkPopup
              onClose={() => setshare_popup(false)}
              url={share_url1}
            />
          </PopupOverlay>
        )}

        <AppContainer>
          {/* <Container2>  */}
          <MainContent>
            <LyricsSection>
              <Header>Chords used in this song</Header>

              <ChordList>
                {uniqueChords.map((chord, index) => {
                  const formattedName = formatChordName(chord); // Format the chord if needed
                  const flatName = convertSharpToFlat(chord); // Convert sharp to flat
                  const imgSrc = images[formattedName];

                  const isSharpChord = chord.includes("#"); // Check if the chord is sharp
                  const chordDisplay = isSharpChord
                    ? `${chord}/${flatName}`
                    : chord; // Show sharp/flat if sharp chord

                  return imgSrc ? (
                    <div className="chord-item" style={{ textAlign: "center" }}>
                      <StyledImage
                        key={index}
                        src={imgSrc}
                        alt={formattedName}
                      />

                      {/* Display chord names */}
                      <p
                        style={{
                          color: "white",
                          fontSize: "14px",
                          marginTop: "10px",
                        }}
                      >
                        {/* Sharp and flat names, or just chord */}
                        {isSharpChord ? (
                          <>
                            {/* Sharp name, clickable */}
                            <span
                              onClick={() => {
                                flat_to_sharp();
                                setSelectedName("formattedName");
                              }}
                              style={{
                                fontSize:
                                  selectedName === "formattedName"
                                    ? "16px"
                                    : "14px",
                                fontWeight:
                                  selectedName === "formattedName"
                                    ? "bold"
                                    : "normal",
                                cursor: "pointer",
                              }}
                            >
                              {chord}
                            </span>
                            /{/* Flat name, clickable */}
                            <span
                              onClick={() => {
                                sharp_to_flat();
                                setSelectedName("flatName");
                              }}
                              style={{
                                fontSize:
                                  selectedName === "flatName" ? "16px" : "12px",
                                fontWeight:
                                  selectedName === "flatName"
                                    ? "bold"
                                    : "normal",
                                cursor: "pointer",
                              }}
                            >
                              {flatName}
                            </span>
                          </>
                        ) : (
                          // For non-sharp chords, just display the chord name
                          <span style={{ fontSize: "14px" }}>{chord}</span>
                        )}
                      </p>
                    </div>
                  ) : null;
                })}
              </ChordList>
              <Rows id="cj1">
                <Header id="lyrics_with_chords">Lyrics with Chords</Header>
                {/* <Select1 onChange={handleSelectChange_version_name}>
                  <option value="original">Original Version</option>
                  {variants.map((variant, index) => (
                    <option key={index} value={variant.name}>
                      {variant.name}
                    </option>
                  ))}
                </Select1> */}

                {/* {variants.length > 0 && ( */}
                <SelectWrapper>
                  <SelectButton onClick={() => setIsOpen(!isOpen)}>
                    {selectedValue === "original"
                      ? "Original Version"
                      : selectedValue}
                  </SelectButton>

                  {isOpen && (
                    <DropdownContainer4>
                      <CustomOption
                        onClick={() => handleSelectChange4("original")}
                      >
                        <OptionText>Original Version</OptionText>
                      </CustomOption>

                      {variants.map((variant, index) => (
                        <CustomOption key={index}>
                          <OptionText
                            onClick={() => handleSelectChange4(variant.name)}
                          >
                            {variant.name}
                          </OptionText>
                          <IconsContainer>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEdit(variant.name);
                              }}
                              style={{ color: "#2684ff" }}
                            >
                              <Edit src={edt_variant} />
                            </IconButton>
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(variant.name);
                              }}
                              style={{ color: "#dc3545" }}
                            >
                              <Trash2 src={del_variant} />
                            </IconButton>
                          </IconsContainer>
                        </CustomOption>
                      ))}
                    </DropdownContainer4>
                  )}
                </SelectWrapper>
                {/* )} */}

                <RightSection>
  {isEditing ? (
    <>
      {/* Show exit edit mode button */}
      <Header11 onClick={exit_edit_mode} src={exit_edt_mode} alt="Exit Edit Mode" />
      
      {/* Show save changes button */}
      <Header10 onClick={save_changes} src={save_as_btn} alt="Save Changes" />
    </>
  ) : (
    // Show edit button when not in edit mode
    <Header1 onClick={edit_chord} src={edit_chords} alt="Edit Chords" />
  )}
</RightSection>

              </Rows>
              {/* <Container>{myVariable}</Container> */}

              <div id="cj">
                {loading1 ? (
                  <CJContainer>
                    <Overlay1 loading={loading1}>
                      <Spinner />
                      {/* <LoadingText>Loading... Please wait</LoadingText> */}
                    </Overlay1>
                  </CJContainer>
                ) : (
                  <div id="cj2">
                    <Container>{myVariable}</Container>
                  </div>
                )}
              </div>

              {/* </CJContainer> */}

              {/* <Container fontSize={fontSize1} >{myVariable}</Container> */}
            </LyricsSection>
          </MainContent>
          <Sidebar>
            <Ad>
              <VideoPlayer videoUrl={videoUrl} />
            </Ad>
            <Header3>Recently Played songs</Header3>
            <RecentlyPlayedList>
              {playlists.map(
                (song, index) =>
                  song.title !== title1 && (
                    <div
                      key={index}
                      onClick={() => {
                        setLoading(true);
                        handleSongCardClick(song.id, song.url);
                      }}
                    >
                      <RecentlyPlayedItem>
                        <SongThumbnail src={song.image} alt={song.title} />
                        <SongDetails>
                          <SongName>{song.title}</SongName>
                        </SongDetails>
                      </RecentlyPlayedItem>
                    </div>
                  )
              )}
              <ViewAllLink onClick={View_all_Songs}>View all</ViewAllLink>
            </RecentlyPlayedList>
          </Sidebar>
          {/* </Container2> */}
        </AppContainer>
      </AppContainer1>
      <ToastContainer />

      <Footer />
      {/* </Screen> */}
    </>
  );
};

// function toggleAutoScroll () {
//   const [isAutoScrolling, setIsAutoScrolling] = useState(false); // Track auto-scroll status
//   const scrollIntervalRef = useRef(null); // Ref to store scroll interval ID
//   let isUserScrolling = false; // Track if user manually scrolls
//   let lastScrollPosition = window.pageYOffset;

//   if (isAutoScrolling) {

//     setIsAutoScrolling(false);
//     stopAutoScroll(); // Stop auto-scroll
//   } else {
//     setIsAutoScrolling(true);
//     autoScroll(); // Start auto-scroll
//   }

//   const autoScroll = () => {
//     const scrollStep = 2;
//     const delay = 50;
//    // setIsAutoScrolling(true);

//     if (isAutoScrolling) {

//       clearInterval(scrollIntervalRef.current);
//       setIsAutoScrolling(false);
//       window.removeEventListener('scroll', onScroll);
//       window.removeEventListener('mousedown', onMouseDown);
//       return;
//     }

//     scrollIntervalRef.current = setInterval(() => {
//       if (!isUserScrolling) {
//         window.scrollBy(0, scrollStep);
//       }

//       if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
//         clearInterval(scrollIntervalRef.current);
//         setIsAutoScrolling(false);
//         window.removeEventListener('scroll', onScroll);
//         window.removeEventListener('mousedown', onMouseDown);
//       }
//     }, delay);

//     const onScroll = () => {
//       const currentScrollPosition = window.pageYOffset;
//       if (currentScrollPosition < lastScrollPosition) {
//         isUserScrolling = true;
//         clearInterval(scrollIntervalRef.current);
//         setIsAutoScrolling(false);
//         window.removeEventListener('scroll', onScroll);
//         window.removeEventListener('mousedown', onMouseDown);
//       }
//       lastScrollPosition = currentScrollPosition;
//     };

//     const onMouseDown = () => {
//       isUserScrolling = true;
//       clearInterval(scrollIntervalRef.current);
//       setIsAutoScrolling(false);
//       window.removeEventListener('scroll', onScroll);
//       window.removeEventListener('mousedown', onMouseDown);
//     };

//     window.addEventListener('scroll', onScroll);
//     window.addEventListener('mousedown', onMouseDown);
//   };

//   const stopAutoScroll = () => {

//     clearInterval(scrollIntervalRef.current);
//     setIsAutoScrolling(false);

//   };

// };

function Downgrade({ onClose, id1, hiddenParam }) {
  const token = getToken();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const delete_song = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${config.apiUrl}/playlists/__default_playlist__/remove`,
        { records: [id1] },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        if (hiddenParam == "all_songs") {
          navigate("/all_songs");
        } else if (hiddenParam == "user") {
          navigate("/user");
        } else {
          navigate("/all_songs");
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        clearToken();
        navigate("/");
      }
    } finally {
      setLoading(false);
    }
  };

  const ActionButton = ({ text, primary, onClick }) => {
    return (
      <Button primary={primary} onClick={onClick}>
        {text}
      </Button>
    );
  };

  return (
    <Modal>
      <HeaderContainer1>
        <WarningText>Warning</WarningText>
        <CloseIcon
          onClick={onClose}
          loading="lazy"
          src={white_close}
          alt="Close"
        />
      </HeaderContainer1>
      <Content1>Are you sure you want to delete this song?</Content1>
      <ActionButton text="Confirm" primary onClick={delete_song} />
      <ActionButton text="Cancel" onClick={onClose} />
    </Modal>
  );
}

const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
  font-size: 12px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
`;

const ButtonContainer01 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center; /* This centers the button vertically if needed */

  @media (max-width: 991px) {
    width: 90%; /* Ensures the container spans the full width on mobile */
    margin: 0 auto; /* Centers the container horizontally */
  }
`;

const InputContainer = styled.section`
  align-self: center;
  display: flex;
  margin-top: 32px;
  width: 100%;
  max-width: 396px;
  flex-direction: column;
  @media (max-width: 991px) {
    width: 90%;
  }
`;

const Label = styled.label`
  color: #fff;
  font: 600 16px Inter, sans-serif;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  min-height: 56px;
`;

const Input = styled.input`
  flex: 1;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 17px 16px;
  border: 1px solid #ececec;
  color: #414141;
  font: 400 13px/32px Poppins, sans-serif;
`;

const Button01 = styled.button`
  align-self: stretch;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 55px;
  min-height: 60px;
  margin-bottom: 40px;
  width: 400px;
  color: #fff;
  text-align: center;
  padding: 0 30px;
  font: 600 16px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005bb0;
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 114, 213, 0.3);
  }
`;

const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const HeaderContainer4 = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const Title01 = styled.div`
  z-index: 0;
  margin: 0;
  font: 600 25px/1.3 Poppins, sans-serif;
  @media (max-width: 991px) {
    font: 600 23px/1.3 Poppins, sans-serif;
  }
`;

const CloseIcon01 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;

const FormContainer = styled.main`
  border-radius: 12px;

  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const FormContainer4 = styled.main`
  border-radius: 12px;

  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Form = styled.form`
  border-radius: 12px;
  background-color: #312f2f;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const Screen = styled.section`
  flex-shrink: 0;
  min-width: 1200px;
`;

const Modal = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  height: 300px;
  padding: 0 0 0px;
  flex-direction: column;
  align-items: center;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    width: 95%;
  }
`;

const Content1 = styled.p`
  color: #3e3635;
  letter-spacing: 0.1px;
  margin-top: 37px;
  font: 500 18px/21px Poppins, sans-serif;
`;

const Content4 = styled.p`
  color: white;
  letter-spacing: 0.1px;
  margin-top: 0px;
  font: 500 14px Poppins, sans-serif;
  line-height: 22.4px;
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
`;
const HeaderContainer1 = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 428px;
  background-color: #0072d5;
  max-width: 100%;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  height: 70px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  position: relative;
`;

const WarningText = styled.h2`
  margin: 0 auto;
  font: 600 24px Poppins, sans-serif;
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 10px;
  cursor: pointer;
`;
const Button = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#428bca" : "transparent")};
  align-self: center;
  margin-top: ${(props) => (props.primary ? "15px" : "5px")};
  width: 100%;
  max-width: 296px;
  color: ${(props) => (props.primary ? "#fff" : "#717171")};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
`;

const Button4 = styled.button`
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  background-color: ${(props) => (props.primary ? "#0072D5" : "transparent")};
  align-self: center;
  margin-top: ${(props) => (props.primary ? "15px" : "5px")};
  width: 100%;
  max-width: 296px;
  color: ${(props) => (props.primary ? "#fff" : "#fff")};
  white-space: nowrap;
  padding: 17px 30px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

const HeaderWrapper = styled.header`
  display: flex;
  width: 428px;
  background-color: #428bca;
  max-width: 100%;
  gap: 0px;
  font-size: 24px;
  white-space: nowrap;
  letter-spacing: -0.24px;
  line-height: 130%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`;

const Title = styled.h2`
  font-family: Poppins, sans-serif;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: auto 0;
  margin-left: 10px;
  margin-right: 30px;
`;

const TransposeButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "#F0984B" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
  margin-left: 5px;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 18px; /* Reduced size to fit inside */
    width: 18px; /* Reduced size to fit inside */
    left: 0px; /* Adjusted to sit inside the slider */
    bottom: 0px;

    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`;

const CloseIcon1 = styled.img`
  object-position: center;
  margin-top: 20px;
  align-self: flex-start;
  cursor: pointer;
  margin-right: 5px;
`;

const Message = styled.p`
  font-family: Poppins, sans-serif;
  font-weight: 500;
  letter-spacing: -0.18px;
  color: #3e3635;
  margin-top: 65px;
`;

const ModalWrapper = styled.section`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  max-width: 428px;
  flex-direction: column;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 23px 25px;
`;

const ViewAllLink = styled.a`
  color: #428bca;
  margin: auto 0;
  font: 16px Poppins, sans-serif;
  margin-right: 35px;
  margin-top: 8px;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-right: 0px;
    flex-direction: row;
  }
`;
const AppContainer = styled.div`
  background-color: #282828;
  display: flex;
  padding-bottom: 19px;
  flex-direction: row;
  gap: 0px;
  align-items: flex-start;
  overflow-x: auto; /* Enable horizontal scroll */
  overflow-y: hidden; /* Disable vertical scroll */
  white-space: nowrap; /* Prevent shrinking or wrapping */
  width: 100%; /* Set width to 100% */

  /* Add this media query for mobile responsiveness */
  @media (max-width: 991px) {
    justify-content: flex-start;
  }
`;

const AppContainer1 = styled.div`
  flex-direction: column;
  background-color: #282828;
  display: flex;

  gap: 0px;
  align-items: flex-start;
  overflow: hidden;
`;

const MainContent = styled.main`
  display: flex;
  padding: 0 20px;
  width: 85%;
  flex-direction: column;
  @media (max-width: 991px) {
  }
`;

const Header = styled.h2`
  color: #fff;
  @media (max-width: 991px) {
    margin-left: 0px;
  }
`;

const Header1 = styled.img`
  cursor: pointer;
  margin-left: 10px;
`;

const Header10 = styled.img`
  cursor: pointer;
  width:120px;
`;
const Header11 = styled.img`
  cursor: pointer;
  width:140px;
`;
const Header5 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 20px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 5px;
  }
`;

const Header6 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 5px;
    white-space: nowrap;
  }
`;

const DropdownContainer = styled.div`
  @media (max-width: 991px) {
    width: 50%;
  }
`;

const Select = styled.select`
  padding: 12px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 190px;
  appearance: none; /* Remove default arrow */

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 15px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border-color: #fff;
    outline: none;
  }

  @media (max-width: 991px) {
    font-size: 10px;
    width: auto;
    /* Adjusted padding to prevent text overlap with arrow */
    padding: 12px 16px 12px 8px; /* Right padding increased to make room for arrow */
    white-space: nowrap;
    justify-content: flex-start; /* Changed from 'left' to 'flex-start' */
    text-align: left;
    background-position: right 6px center; /* Slightly adjusted arrow position */
    background-size: 10px;
    margin-left: 5px;
    margin-right: 0px;
  }
`;

const Select1 = styled.select`
  height: 50px;
  padding: 12px; /* Reduced padding to decrease height */
  margin-left: 15px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 210px;
  appearance: none; /* Remove default arrow */

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 15px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:focus {
    border-color: #fff;
    outline: none;
  }
  @media (max-width: 991px) {
    font-size: 10px;
    width: auto;
    /* Adjusted padding to prevent text overlap with arrow */
    padding: 12px 16px 12px 8px; /* Right padding increased to make room for arrow */
    white-space: nowrap;
    justify-content: flex-start; /* Changed from 'left' to 'flex-start' */
    text-align: left;
    background-position: right 6px center; /* Slightly adjusted arrow position */
    background-size: 10px;
    margin-left: 5px;
    margin-right: 0px;
  }
`;

const DisplayText = styled.p`
  font-size: 18px;
  color: #333;
  margin-top: 15px;
`;

const Header61 = styled.div`
  color: #fff;
  font: 400 15px Poppins, sans-serif;
  text-align: center; /* Center text horizontally */
  display: flex; /* Use flexbox to center content */
  justify-content: center; /* Horizontal centering in flexbox */
  align-items: center; /* Vertical centering in flexbox */
  margin-left: 10px;
  border-radius: 4px; /* Add border-radius */
  width: 90px;
  padding: 12px;
  background-color: ${({ isActive }) => (isActive ? "#F0984B" : "#242323")};

  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 0px;
    padding: 12px;
    width: 50px;
    margin-right: 15px;
  }
`;

const FontSizeControls = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 10px;
`;
const FontSizeButton1 = styled.button`
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "blue" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    font-size: 10px;
  }
`;

const Header8 = styled.div`
  color: #fff;
  font: 400 12px Poppins, sans-serif;
  margin-top: 10px;
  letter-spacing: 0.28px;
  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 5px;
  }
`;

const CJContainer = styled.div`
  position: relative; /* Make the parent position relative for absolute positioning of overlay */
  min-height: 350px;

  min-width: 900px;
  width: 100%;
  background-color: #282828;
  @media (max-width: 991px) {
    min-width: 600px;
  }
`;

// Styled component for the overlay
const Overlay1 = styled.div`
  position: absolute; /* Position it absolutely within the CJContainer */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Stretch to the right */
  bottom: 0; /* Stretch to the bottom */
  display: flex; /* Center the spinner */
  align-items: center; /* Vertically center */
  justify-content: center; /* Horizontally center */
  background-color: #282828;
  z-index: 1; /* Ensure it's above other content */
`;

const Header7 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 4px;
  margin-right: 4px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 2px;
    margin-right: 2px;
  }
`;

const Rows = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between; /* Ensures items are spaced apart */
  align-items: center; /* Aligns items vertically in the center */
  width: 100%; /* Optional: makes Rows take full width */
`;

const RightSection = styled.div`
  display: flex;
  gap: 8px; /* Adjust gap between items if needed */
  align-items: center;
  margin-left: auto; /* Pushes RightSection to the far right */
`;

const Image1 = styled.img`
  cursor: pointer;
  margin-left: 10px;
`;

const Image2 = styled.img`
  cursor: pointer;
  margin-left: 10px;
  margin-right: 20px;
`;
const Image3 = styled.img`
  cursor: pointer;
  margin-left: 5px;

  @media (max-width: 991px) {
    height: 33px;
  }
`;

const Image4 = styled.img`
  cursor: pointer;
  margin-left: 30px;
`;

const Con = styled.div`
  position: fixed;
  display: flex;
  bottom: 10px; /* Distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to truly center horizontally */
  z-index: 1000;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: row;
  padding: 5px;
  background-color: #333232; /* Optional background color */
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  height: 65px;
  width: 730px;
  margin-left: 50px;

  /* Mobile adjustments */
  @media (max-width: 991px) {
    width: 90%; /* Occupy 90% width on mobile */
    margin: 0 auto; /* Center horizontally on mobile */
    left: 50%; /* Keep horizontal centering */
    transform: translateX(-50%); /* Maintain true centering */
    padding: 5px;
    height: 10%;
    margin-left: 30px;
  }
`;

const SwitchInputChecked = styled(SwitchInput)`
  &:checked + ${Slider} {
    background-color: #f0984b;
  }

  &:checked + ${Slider}:before {
    transform: translateX(26px);
  }
`;

// const Con = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: row;
//   padding: 5px;
//   background-color: #333232;
//   border-radius: 8px;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
//   height: 65px;
//   width: 200px;

//   /* Mobile adjustments */
//   @media (max-width: 991px) {
//     width: 90%;
//     padding: 5px;
//     height: 10%;
//   }
// `;

const Header3 = styled.h3`
  color: #fff;
`;

const SongSection = styled.section`
  display: flex;
  gap: 20px;
  margin-top: 32px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const AlbumCover = styled.img`
  max-width: 350px;
  height: 131px;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 150px;
    height: 100px;
    margin-left: 10px;
  }
`;

const Button_back = styled.img`
  max-width: 80px;
  cursor: pointer;
  margin-left: 20px;
  @media (max-width: 991px) {
    display: none;
  }
`;

const Button_back1 = styled.img`
  max-width: 35px;
  cursor: pointer;
  margin-left: 10px;
`;

const SongInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const SongInfo3 = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

const Songs1 = styled.div`
  display: flex;
  flex-direction: row;
`;

const SongTitle = styled.h1`
  color: #fff;
  font: 600 18px/140% Poppins, sans-serif;
  letter-spacing: -0.28px;
  margin-left: 10px;
  @media (max-width: 991px) {
    font: 600 14px/140% Poppins, sans-serif;
    width: 70%;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2; // Adjust line height if needed
    max-height: calc(1.2em * 2); // Line height * number of lines
    margin-top: 35px;
    margin-left: 10px;
  }
`;

const SongActions = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-top: 0px;
`;

const SongActions2 = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-top: 0px;
`;

const SongActions1 = styled.div`
  display: flex;
  align-items: center;
  gap: 0px; /* No gap between buttons */
  margin-top: 12px;
`;
const ButtonImage = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
`;

const Image10 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
`;

const ButtonImage1 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  background: transparent; /* Ensures button has no background */
  cursor: pointer;

  /* Ensures images inside this button have the correct size */
  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const DownloadButton0 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: relative; /* Needed for positioning the tooltip */

  /* Ensures images inside this button have the correct size */
  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const ButtonImage0 = styled.img`
  cursor: pointer; /* Ensures pointer cursor on hover */
`;

const Tooltip = styled.div`
  position: absolute;
  bottom: 150%; /* Position above the button, adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: black;
  border: 2px solid #fff;
  padding: 8px;
  border-radius: 4px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
  z-index: 1;
  font-size: 10px;
`;

const Imagearrow = styled.img`
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute; /* Position relative to the button */
  top: -30px; /* Adjust position as needed */
  left: 50%;
  transform: translateX(-50%);
`;

const StyledDownloadButton = styled(DownloadButton0)`
  &:hover ${Tooltip} {
    opacity: 1;
  }

  &:hover ${Imagearrow} {
    opacity: 1;
  }
`;

const CurrentValue = styled.div`
  color: #fff;
`;

const DownloadButton1 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  padding: 0px;
  background: transparent; /* Ensures button has no background */
  cursor: pointer;
  margin-left: 10px;

  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const DownloadButton2 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 0px;
  background: transparent;
  cursor: pointer;

  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const DownloadButton3 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 0px;
  background: transparent;
  cursor: pointer;

  img {
    height: 35px;
  }
`;

const DownloadButton4 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 4px;
  background: transparent;
  cursor: pointer;

  img {
    height: 32px;
  }
`;
const DownloadButton10 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 0px;
  background: transparent;
  cursor: pointer;

  img {
    height: 32px;
  }
`;

const LyricsSection = styled.section`
  margin-top: 32px;
`;

const LyricsSection1 = styled.section`
  margin-top: 32px;
  display: none;
`;

const ChordList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
  margin-left: 0px;
  @media (max-width: 768px) {
    min-width: 900px;
    width: 100%; /* Ensure it stays full width on mobile */
  }
`;

const Verse = styled.div`
  margin-top: 24px;
  margin-right: 0px;
  color: #fff;
`;

const Sidebar = styled.aside`
  background-color: #312f2f;
  width: 30%;
  padding: 20px;
  margin-left: 0px;
  justify-content: right;
  margin-top: 80px;
  margin-right: 20px;
  @media (max-width: 991px) {
    width: 100%;
    display: none;
  }
`;

const Ad = styled.div`
  background-color: #fff;
  border-radius: 2px;
`;

const RecentlyPlayedList = styled.ul`
  list-style: none;
  padding: 0;

  margin: 12px 0 0;
`;

const Con1 = styled.div`
  position: fixed;
  display: flex;
  bottom: 15px; /* Distance from the bottom */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Adjust to truly center horizontally */
  z-index: 1000;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: column;
  padding: 5px;
  background-color: #312f2f; /* Optional background color */
  border-radius: 8px; /* Optional rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
  height: 85px;

  /* Mobile adjustments */
  @media (max-width: 991px) {
    width: 100%; /* Occupy 90% width on mobile */
    margin: 0 auto; /* Center horizontally on mobile */
    left: 50%; /* Keep horizontal centering */
    transform: translateX(-50%); /* Maintain true centering */
    padding: 5px;
    bottom: 0px;
    height: 65px;
  }
`;

const Con2 = styled.div`

   display: flex; /* Ensure flexbox layout */
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Center items vertically */
  flex-direction: row; /* Align items in a row */
  margin-bottom: 5px; /* Space between Con2 and Header61 */
 
  }
`;

const Con3 = styled.div`

  width:45%;
  
   display: flex; /* Use flexbox layout */
  flex-direction: row; /* Align items in a row */
  justify-content: space-between; 
  }
`;

const Con4 = styled.div`
  width:55%;
  
   display: flex; /* Use flexbox layout */
  flex-direction: row; /* Align items in a row */
  justify-content: space-between; 
  }
`;

const RecentlyPlayedItem = styled.li`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  cursor: pointer;
`;

const SongThumbnail = styled.img`
  width: 78px;
  height: auto;
`;

const SongDetails = styled.div`
  flex-direction: column;
  justify-content: top;
`;

const SongName = styled.p`
  color: #fff;
  font: 600 14px/19px Poppins, sans-serif;
  letter-spacing: -0.2px;
  margin: 5;
  width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Ensure text stays on a single line */
`;

const TransposeButton1 = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "#F0984B" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    padding: 8px 12px;
    font-size: 10px;
    white-space: nowrap;
  }
`;

const ButtonContainer1 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  margin-left: 5px;
  margin-right: 10px;
`;

const Header51 = styled.div`
  color: #fff;
  font: 550 15px Poppins, sans-serif;

  align-items: center;
  margin-left: 10px;
  @media (max-width: 991px) {
    font-size: 12px;
    margin-left: 10px;
    margin-top: 7px;
    white-space: nowrap;
  }
`;

const PopupOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other content */
`;

const PopupOverlay1 = styled.div`
  z-index: 1000; /* Ensure it's above other content */
`;

const PopupOverlay3 = styled.div`
  position: fixed; /* Ensures the overlay covers the entire screen */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  width: 100vw; /* Full screen width */
  height: 100vh; /* Full screen height */
  z-index: 1000; /* Ensure it's above other content */
  background: rgba(
    0,
    0,
    0,
    0.5
  ); /* Semi-transparent black for dimming effect */
  display: flex;
  justify-content: center; /* Center the popup horizontally */
  align-items: center; /* Center the popup vertically */
`;

export const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;
export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;
export const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const Container2 = styled.div`
  display: inline-block; /* Ensures content stays inline */
  white-space: nowrap; /* Prevent line wrapping */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  width: 100%; /* Take full width of the parent */
  flex-direction: column;
  min-width: 1300px;

  /* Add padding or margin as per your design needs */

  /* For mobile responsiveness */
  @media (max-width: 768px) {
    width: 100%; /* Ensure it stays full width on mobile */
  }
`;

const StyledImage = styled.img`
  display: inline-block;
  max-width: 120px;
  height: auto;
  margin: 0 10px;

  @media (max-width: 768px) {
    max-width: 80px;
  }
`;

const SpeedControl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
  margin-left: 10px;
  @media (max-width: 991px) {
    margin-left: 0px;
    gap: 4px;
    margin-right: 10px;
  }
`;

const SpeedButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) => (props.isActive ? "blue" : "#242323")};
  color: ${(props) => (props.isActive ? "#fff" : "#fff")};
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  @media (max-width: 991px) {
    padding: 8px 12px;
    font-size: 10px;
    margin-left: 0px;
  }
`;

const SpeedDisplay = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #222;
  color: white;
  border: 2px solid white;
  border-radius: 8px;
  font-size: 18px;
  margin: 0 5px;
`;

const Container = styled.div`
  display: inline-block; /* Ensures content stays inline */
  white-space: nowrap; /* Prevent line wrapping */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Disable vertical scrolling */
  width: 100%; /* Take full width of the parent */
  flex-direction: column;
  min-width: 900px;
  min-height: 350px;
  // font-size: ${(props) => props.fontSize}px;

  /* Add padding or margin as per your design needs */

  /* For mobile responsiveness */
  @media (max-width: 768px) {
    min-width: 900px;
    width: 100%; /* Ensure it stays full width on mobile */
  }
`;

const Line = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${(props) => (props.type === "words" ? "10px" : "")};
`;

// const Box = styled.div`
//   font-size: ${(props) => (props.type === "words" ? 15 : 15)}px;
//   width: ${(props) => props.width}px;
//   color: ${(props) => (props.type === "words" ? "#FFFFFF" : "#F0984B")};
//   display: inline-block;
//   padding: 3px;
//   border: ${(props) =>
//     props.editMode
//       ? `1px solid ${props.type === "words" ? "#FFFFFF" : "#F0984B"}`
//       : "none"};
//   margin-bottom: ${(props) => (props.isGrouped ? "14px" : "4px")};
//   white-space: nowrap;
// `;


const Box = styled.div`
  font-size: ${(props) => (props.type === "words" ? 15 : 15)}px;
  width: ${(props) => props.width}px;
  color: ${(props) => (props.type === "words" ? "#FFFFFF" : "#F0984B")};
  display: inline-block;
  padding: 3px;
  border: ${(props) =>
    props.editMode
      ? `1px solid ${
          props.type === "words"
            ? "rgba(255, 255, 255, 0.2)"  // White with 50% transparency
            : "rgba(240, 152, 75, 0.2)"   // #F0984B with 50% transparency
        }`
      : "none"};
  margin-bottom: ${(props) => (props.isGrouped ? "14px" : "4px")};
  white-space: nowrap;
`;

const DownloadButton5 = styled.button`
  display: flex;
  align-items: center;
  border: none;
  margin-left: 3px;
  background: transparent;
  cursor: pointer;

  img {
    height: 32px; /* Adjust size as needed */
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 185px;
  left: 200px;
  background-color: #fff;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 10;
`;

const MenuItem = styled.div`
  padding: 12px 24px;
  cursor: pointer;
  color: #333;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const FontSizeButton = styled.button`
  background-color: #f0f0f0; /* Light background */
  border: 1px solid #ccc; /* Border for the button */
  color: #333; /* Button text color */
  padding: 8px 16px; /* Padding for the button */
  margin: 0 8px; /* Space between the buttons */
  font-size: 18px; /* Default font size for button text */
  border-radius: 4px; /* Rounded corners */
  cursor: pointer; /* Show pointer on hover */

  &:hover {
    background-color: #e0e0e0; /* Slightly darker background on hover */
  }

  &:active {
    background-color: #d0d0d0; /* Even darker background when pressed */
  }
`;

// const SpeedButton = styled.button`
//   background-color: #333; /* Dark background for contrast */
//   color: #fff; /* White text color */
//   border: none;
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   font-size: 18px;
//   cursor: pointer;
//   margin: 0 10px;
//   transition: background-color 0.2s ease;

//   &:hover {
//     background-color: #555; /* Change color slightly on hover */
//   }

//   &:active {
//     background-color: #111; /* Even darker when pressed */
//   }
// `;

const Select4 = styled.select`
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
  }
`;

const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 190px;
  margin-left: 15px;
  margin-top: 5px;
  @media (max-width: 991px) {
    min-width: 160px; /* Full width on mobile */
    margin-left: 15px;
    margin-right: 0;
    margin-top: 5px;
  }
`;

const SelectWrapper3 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 190px;

  @media (max-width: 991px) {
    min-width: 160px; /* Full width on mobile */
  }
`;

const CustomOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #f0984b; /* Changes to orange on hover */
  }
`;

const CustomOption3 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  cursor: pointer;
  color: white;

  &:hover {
    background-color: #f0984b; /* Changes to orange on hover */
  }
`;

const DropdownContainer4 = styled.div`
  position: absolute;
  top: calc(100% - 8px);
  left: 0; /* Align with the left side of SelectButton */
  width: 100%;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
`;

const DropdownContainer5 = styled.div`
  position: absolute;
  top: calc(100%);
  left: 0; /* Align with the left side of SelectButton */
  width: 100%;
  background-color: #333232;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
`;

const OptionText = styled.span`
  flex-grow: 1;
  color: white;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: #e0e0e0;
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

const SelectButton = styled.button`
  height: 50px;
  padding: 12px; /* Reduced padding to decrease height */
  margin-left: 15px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 100%;
  appearance: none; /* Remove default arrow */
  cursor: pointer;

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 15px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;

const SelectButton3 = styled.button`
  height: 45px;
  padding: 12px; /* Reduced padding to decrease height */
  margin-left: 15px;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #fff;
  background-color: #333232;
  color: white;
  text-align: center;
  width: 100%;
  appearance: none; /* Remove default arrow */
  cursor: pointer;

  /* Position the text and arrow */
  background-image: url(${down_drop_arrow}); /* Path to your arrow image */
  background-repeat: no-repeat;
  background-position: right 8px center; /* Adjust the position of the arrow */
  background-size: 15px; /* Size of the arrow */

  /* Flexbox for content alignment */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  &:focus {
    border-color: #fff;
    outline: none;
  }
`;

const Edit = styled.img``;

const Trash2 = styled.img``;

export default MusicApp1;
