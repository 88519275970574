import React from 'react';
import styled from 'styled-components';
import PreNav from '../navigation/premium_nav';
import PreNavOffline from '../navigation/pre_nav_offline';
import Footer from '../footer_lan';
import { getToken } from '../utils';
import { useNavigate } from 'react-router-dom';

function TermsAndConditions() {
  const navigate = useNavigate();
  const token = getToken();
  const contact = () => {
    window.scrollTo(0, 0);
    navigate("/contact");
  };

  return (
    <MainContainer>
      {token ? <PreNav /> : <PreNavOffline />}
      <ContentWrapper>
        <TermsContainer>
          <TermsTitle>Terms and Conditions For Jammin ai</TermsTitle>
          <Divider />
          <Section>
            <SectionTitle>1. Welcome to Jammin.ai!</SectionTitle>
            <SectionText>
              These terms and conditions ("Terms") govern your access and use of the Jammin.ai website, mobile applications, API, and all related services (collectively, the "Jammin.ai Service"). By accessing or using the Jammin.ai Service, you agree to be bound by these Terms and our Privacy Policy.
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>2. Your Use</SectionTitle>
            <SectionText>
              <Order>
                <List><b>Personal Use Only:</b> The Jammin.ai Service is for your personal, non-commercial use only.</List>
                
                <List><b>Changes to Service:</b> We may modify the Jammin.ai Service at any time, including adding or removing features.</List>
                
                <List><b>"As Is" Service:</b> The Jammin.ai Service is provided "as is" without warranties of any kind.</List>
              </Order>
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>3. Restrictions on Use</SectionTitle>
            <SectionText>
              <Order>
                <List>You may not use automated tools to access the Jammin.ai Service.</List>
                <List>You are responsible for the data you create and use with the Service.</List>
                <List>You indemnify Jammin.ai against any claims arising from your use of the Service.</List>
                <List>We may limit or restrict your access to the Jammin.ai Service for any reason.</List>
              </Order>
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>4. YouTube API Services</SectionTitle>
            <SectionText>
              Jammin.ai uses YouTube API Services to play embedded videos, subject to YouTube's Terms of Service.
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>5. Subscriptions</SectionTitle>
            <SectionText>
              <Order>
                <List><b>Renewal:</b> If you choose a monthly or other subscription, it will automatically renew unless you cancel before the renewal date.</List>
                 
                <List> <b>Payment:</b> Subscriptions are charged directly to your payment method.</List>
                  
                <List> <b>Cancellation:</b> You may cancel your subscription at any time. Your cancellation will take effect at the end of your current billing cycle. For example, if you are on a monthly plan, your cancellation will be effective at the end of the month. If you are on an annual plan, it will be effective at the end of the year.</List>
              </Order>
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>6. Fees and Payments</SectionTitle>
            <SectionText>
              <Order>
                <List>Some parts of the Jammin.ai Service are free, some require payment. Prices are listed on the website or app.</List>
                <List>We may change pricing in the future.</List>
              </Order>
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>7. Account and Password</SectionTitle>
            <SectionText>
            <Order>
              <List>You are responsible for maintaining the security of your account credentials.</List>
              <List> Jammin.ai is not liable for unauthorized account use.</List>
            </Order>
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>8. Lyrics</SectionTitle>
            <SectionText>
              <Order>
                <List>Lyrics and chords are for your personal, non-commercial use only.</List>
                <List>You may not reproduce, distribute, modify, or sell lyrics provided by Jammin.ai.</List>
              </Order>
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>9. Intellectual Property</SectionTitle>
            <SectionText>
              Jammin.ai owns all intellectual property rights related to the Service. You may not publicly copy or reproduce any part of the Service.
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>10. Complaints</SectionTitle>
            <SectionText>
              You can submit complaints about the Jammin.ai Service to <EmailLink onClick={contact}>contact us</EmailLink> We will respond within 3 days.
            </SectionText>
          </Section>
          <Section>
            <SectionTitle>11. Governing Law and Jurisdiction</SectionTitle>
            <SectionText>
              These Terms of Service constitute the complete agreement between you and Jammin.ai and govern your use of the Services, superseding any previous agreements. Additional terms of service may apply when using affiliate or third-party services, third-party content, or third-party software. These Terms of Service are subject to the laws of the State of California, without regard to its conflict of law provisions. For any disputes or claims not subject to arbitration, both you and Jammin.ai submit to the personal and exclusive jurisdiction of the state and federal courts located within Santa Clara County, California.<br/><br/>
              By downloading, accessing, or logging into our App, you acknowledge that you have read, understood, and agreed to be bound by these Terms and Conditions. If you do not agree with any of the terms outlined herein, we kindly request that you refrain from downloading, accessing, or logging into our App. We encourage you to review these Terms periodically, as they may be updated or modified to reflect changes in our services or legal requirements. Your continued use of the App following any such changes constitutes your acceptance of the revised Terms. If you have any questions or concerns regarding these Terms and Conditions, please contact our support team for further assistance. Thank you for choosing Jammin.ai.
            </SectionText>
          </Section>

          <Section>
            <SectionTitle>12. Free Trial Limitations:</SectionTitle>
            <SectionText>
              <Order>
                <List><b>Limited to One Free Trial:</b>  You are only eligible for one (1) free trial of jammin.ai. If you cancel your free trial before the end of the period, you will not be able to access another free trial in the future.</List>
                
                <List><b>Automatic Subscription Upon Reactivation:</b> Upon reactivation of your subscription after a canceled free trial, your subscription will begin immediately. You will be charged the standard subscription fee at that time..</List>
                
               
              </Order>
            </SectionText>
          </Section>

        </TermsContainer>
      </ContentWrapper>
      <Footer />
    </MainContainer>
  );
}

// Styled Components
const Section = styled.div`
  margin-bottom: 15px;
`;
const MainContainer = styled.main`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #121212;
  width: 100%;
`;
const Order=styled.ul`

`;

const List =styled.li`

`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 30px;
  box-sizing: border-box;
`;

const TermsContainer = styled.section`
  display: flex;
  max-width: 900px;
  flex-direction: column;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  letter-spacing: -0.16px;
  padding: 0 20px;
  margin: 20px 0; 
`;

const TermsTitle = styled.h1`
  font-size: 32px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
`;

const SectionTitle = styled.h2`
  letter-spacing: -0.2px;
  margin-top: 20px;
  width: 100%;
  font: 20px Poppins, sans-serif;
  text-align: justify; /* Justify text */

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SectionText = styled.p`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  color: #d4d4d4;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: justify; /* Justify text */


  ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 10px;
  }

  li {
    margin-bottom: 10px;
  }
`;
const EmailLink = styled.a`
  cursor:pointer;
  font-weight: 600;
  color: #428bca;
  text-decoration: none;
`;

const Divider = styled.hr`
  margin-top: 7px;
  width: 100%;
  border: 1px solid #7d7d7d;
`;

export default TermsAndConditions;
