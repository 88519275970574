import React, { useState, useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import axios from "axios";
import config from "../../config";
import { accept_btn, terms_pic, set_default_card } from "../../assets/images";

import { getToken, clearToken ,getfirstname} from "../utils";
import { useNavigate } from "react-router-dom";

function useWindowWidth() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowWidth;
}

function Terms1({ onClose }) {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 991;

  const token = getToken();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let names = getfirstname();
if (!names) {
  names = "jammin";
}



  if (token == null) {
    navigate("/");
  }

  const terms_page = () => {
    window.scrollTo(0, 0);

    navigate(`/terms`);
  };

  const accept = async () => {
    setLoading(true);
        try {
          const response = await axios.post(`${config.apiUrl}/users/accept_terms`, {
            "name":names
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            }
          });
          if (response.status === 200) {
            const ac="abc";
            localStorage.setItem("terms_accepted", ac);
            onClose();
            setLoading(false);
          }
        } catch (error) {
          if(error.response && error.response.status === 401) {
            clearToken();
            navigate("/");
          }
        }
  };

  return (
    <PopupContainer>
      <Overlay loading={loading}>
        <Spinner />
      </Overlay>

      <ConfirmationCard>
        <HeaderWrapper>
          <LogoContainer>
            <MainLogo loading="lazy" src={terms_pic} alt="Main logo" />
          </LogoContainer>
        </HeaderWrapper>

        <Message>Accept Terms and Conditions:</Message>
        <Message1>
          By clicking <span className="bold">"Accept"</span>, you agree to our
        </Message1>

        <Message2 onClick={terms_page}>Terms of Service</Message2>

        <Header1 onClick={accept} src={accept_btn} />
      </ConfirmationCard>
    </PopupContainer>
  );
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* Ensure it's above other content */
  display: ${(props) => (props.loading ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5em;
`;

const spin = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;
const Spinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const PopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Optional: for a dark overlay effect */
  z-index: 9999; /* Ensure it appears on top of other content */
`;

const Header1 = styled.img`
  cursor: pointer;
  margin-top: 10px;
`;

const BillingDetails = styled.section`
  letter-spacing: 0.18px;
  text-align: center;
  color: #121212;
  margin-top: 10px;
  font: 500 14px/20px Poppins, sans-serif;
`;

const EmailStyled = styled.span`
  font: 700 14px/20px Poppins, sans-serif;
`;

const Message = styled.p`
  letter-spacing: 0.2px;
  text-align: center;
  color: #121212;
  margin-top: 20px;
  font: 700 16px/22px Poppins, sans-serif;
`;

const Message1 = styled.div`
  letter-spacing: 0.2px;
  text-align: center;
  color: #121212;
  margin-top: 0px;
  font: 400 16px/22px Poppins, sans-serif;

  .bold {
    font-weight: 700; /* Makes "Accept" bold */
  }
`;
const Message2 = styled.div`
  color: #0072d5; /* Makes the text blue */
  text-decoration: underline; /* Adds an underline */
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
  font: 700 16px/22px Poppins, sans-serif;
  margin-top: 10px;
`;

const HeaderWrapper = styled.header`
  background-color: #9dbcd9;
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 12px;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 15px;
  right: 19px;
  width: 34px; /* Adjust size as needed */
  height: 34px; /* Adjust size as needed */
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MainLogo = styled.img`
  width: 100%;
  max-width: 440px;

  height: auto;

  margin-top: 12px;
`;

const ConfirmationCard = styled.div`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 440px;
  height: 455px;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  @media (max-width: 991px) {
    width: 94%;

    margin-left: 10px;
    margin-right: 8px;
  }
`;

export default Terms1;
