import React from "react";

const VideoPlayer = ({ videoUrl }) => {
  return (
    <div className="video-player">
      <style>
        {`
          .video-player {
            position: relative;
            width: 461px;
            height: 300px;
            overflow: hidden;    
          }
          .video-player iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            margin: 0; 
            padding: 0;
          }
        `}
      </style>
      <iframe
        src={videoUrl}
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="video player"
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
