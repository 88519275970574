import * as React from "react";
import styled from "styled-components";
import { new_logo } from '../assets/images';
import { useNavigate } from 'react-router-dom';

const MainContainer = styled.main`
  background-color: #312f2f;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  height:200px;
  text-align: center;
  padding: 41px 0;
  overflow: hidden; 
  @media (max-width: 991px) {
    height:100%;
  }
`;

const LogoImage = styled.img`
  aspect-ratio: 6.25;
  object-fit: auto;
  object-position: center;
  width: 306px;
  max-width: 100%;
`;

const Navigation = styled.nav`
  display: flex;
  margin-top: 34px;
  padding-right: 20px;
  gap: 20px;
  font-size: 18px;
  font-style:'Source Sans Pro';
  justify-content: space-between;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    flex-direction: column;
    font-size: 16px;
    margin-top: 24px;
    gap: 15px;
   
  }
`;

const Separator = styled.hr`
  border-color: rgba(222, 227, 235, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #dee3eb;
  align-self: stretch;
  min-height: 1px;
  margin-top: 40px;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const FooterText = styled.p`
  letter-spacing: -0.16px;
  margin: 17px 0 0 0px;
  font: 300 16px/140% 'Poppins', sans-serif;
  text-align: center;
`;

const FooterNote = styled.small`
  margin: 15px 0 0 0;
  font: 700 13px 'Ubuntu', sans-serif;
  text-align: center;
  display: block;
  whitespace:pre-wrap;
`;

const FooterNote1 = styled.small`
  font: 700 13px 'Ubuntu', sans-serif;
  text-align: right;
  margin-right: 20px;
  display: block;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 11px;
`;

const NavLink = styled.a`
  font-family: 'Source Sans Pro', sans-serif;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  &:focus {
    outline: 2px solid #fff;
  }
  &:hover {
   
  }
`;


const FooterNoteContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
`;

function Footer(){

  const navigate = useNavigate();

  const scroll = (id) => {
      navigate("/");
      setTimeout(() => {
          const element = document.getElementById(id);
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          } else {
              // console.warn(`Element with id '${id}' not found.`);
          }
      }, 100); // Adjust the delay as needed
  };

  const contact = () => {
    window.scrollTo(0, 0);
    navigate("/contact");
  };

  const about_us = () => {
    window.scrollTo(0, 0);
    navigate("/about_us");
  };

  const terms = () => {
    window.scrollTo(0, 0);
    navigate("/terms");
  };


  const privacy = () => {
    window.scrollTo(0, 0);
    navigate("/privacy_policy");
  };


  const faq = () => {
    window.scrollTo(0, 0);
    navigate("/faq");
  };

  
  return (
    <MainContainer>
      <LogoImage
        loading="lazy"
        src={new_logo}
        alt="Logo"
      />
      <Navigation>
        <NavLink onClick={() => scroll('home-section')}>Home</NavLink>
        <NavLink onClick={() => scroll('pricing-section')}>Pricing</NavLink>
        <NavLink onClick={contact}>Contact Us</NavLink>
        <NavLink onClick={about_us}>About us</NavLink>
        <NavLink onClick={terms}>Terms and Conditions</NavLink>
        <NavLink onClick={privacy}>Privacy Policy</NavLink>
        <NavLink onClick={faq}>FAQ</NavLink>
      </Navigation>
      <Separator />
      <FooterText>Made by Musicians for Musicians</FooterText>
      <FooterNote>© 2024 Jammin.Ai. All Rights Reserved</FooterNote>
      <FooterNote1>v0.12_08 November</FooterNote1>
    </MainContainer>
  );
  
}

export default Footer;
