import React, { useState } from 'react';
import styled from "styled-components";
import {
    getToken,
} from "../utils";
import axios from "axios";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { clearToken } from "../utils";


function NewPlaylistForm({ onClose }) {
    const navigate = useNavigate();
    const token = getToken();

    const [playlistTitle, setPlaylistTitle] = useState('');
    const [errormsg, setErrormsg] = useState(''); // Only using errormsg for error display

    const handleCreateClick = (event) => {
        event.preventDefault();

        if (playlistTitle.trim() === '') {
            setErrormsg("Please enter a title");
        } else {
            setErrormsg(''); // Clear previous errors
            add_playlist(playlistTitle);
        }
    };

    const add_playlist = async (title) => {
        try {
            const response = await axios.post(`${config.apiUrl}/playlists/`,
                {
                    "name": title
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                });

            if (response.status === 200) {
                localStorage.setItem("playlistadded123",response.status);
                onClose();
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                clearToken();
                navigate("/");
            } else if (error.response && error.response.status === 400) {
                setErrormsg(error.response.data.message); // Display the actual error message
            } else {
                setErrormsg("An unknown error occurred"); // Handle any other errors
            }
        }
    };

    return (
        <FormContainer>
            <Form>
                <HeaderContainer>
                    <Title>New Playlist</Title>
                    <CloseButton onClick={onClose}>×</CloseButton>
                </HeaderContainer>

                <InputContainer>
                    <Label htmlFor="playlistTitle">Title</Label>
                    <InputWrapper>
                        <Input
                            id="playlistTitle"
                            type="text"
                            placeholder="Playlist title"
                            aria-label="Enter playlist title"
                            value={playlistTitle}
                            onChange={(e) => setPlaylistTitle(e.target.value)}
                        />
                    </InputWrapper>
                    {errormsg && <ErrorMessage>{errormsg}</ErrorMessage>} {/* Show error message only if present */}
                </InputContainer>

                <ButtonContainer>
                    <Button onClick={handleCreateClick}>Create</Button>
                </ButtonContainer>
            </Form>
        </FormContainer>
    );
}


const ErrorMessage = styled.p`
  color: red;
  margin-top: 5px;
  font-size: 12px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 35px;
  right: 30px;
  background: none;
  border: none;
  color: #fff;
  font-size: 34px;
  cursor: pointer;
`;

const ButtonContainer = styled.div` 
  display: flex;
  justify-content: center;
  align-items: center; /* This centers the button vertically if needed */

  @media (max-width: 991px) {
    width: 90%; /* Ensures the container spans the full width on mobile */
    margin: 0 auto; /* Centers the container horizontally */
  }
`;


const InputContainer = styled.section`
  align-self: center;
  display: flex;
  margin-top: 32px;
  width: 100%;
  max-width: 396px;
  flex-direction: column;
  @media (max-width: 991px) {
   width: 90%;
  }
`;

const Label = styled.label`
  color: #fff;
  font: 600 16px Inter, sans-serif;
`;

const InputWrapper = styled.div`
  display: flex;
  margin-top: 8px;
  min-height: 56px;
`;

const Input = styled.input`
  flex: 1;
  border-radius: 4px;
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 17px 16px;
  border: 1px solid #ececec;
  color: #414141;
  font: 400 13px/32px Poppins, sans-serif;
`;

const Button = styled.button`
  align-self: stretch;
  border-radius: 4px;
  background-color: #0072d5;
  margin-top: 55px;
  min-height: 60px;
  margin-bottom:40px;
  width:400px;
  color: #fff;
  text-align: center;
  padding: 0 30px;
  font: 600 16px Poppins, sans-serif;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #005bb0;
  }&:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 114, 213, 0.3);
  }
`;

const HeaderContainer = styled.header`
  position: relative;
  display: flex;
  min-height: 68px;
  align-items: center;
  justify-content: center;
  padding: 21px 16px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.2px;
  font: 600 20px/1.3 Poppins, sans-serif;
`;

const Title = styled.div`
  z-index: 0;
  margin: 0;
   font: 600 30px/1.3 Poppins, sans-serif;
     @media (max-width: 991px) {
   font: 600 23px/1.3 Poppins, sans-serif;
  }
`;

const CloseIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
`;

const FormContainer = styled.main`
 
  border-radius: 12px;
 
  display: flex;
  width: 428px;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
   margin-left:15px;
   margin-right:15px
  }
`;

const Form = styled.form`
  border-radius: 12px;
  background-color: #312f2f;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export default NewPlaylistForm;