import React from "react";
import styled from "styled-components";
import { after_set_default_card, white_close } from "../../assets/images";
import { getToken } from "../utils";
import { useNavigate } from "react-router-dom";


function After_set_cards({ onClose }) {
 
  const token = getToken();
  const navigate = useNavigate();
  if(token==null){
    navigate("/");
  }
 

  const handlethankClose = () => {
    onClose();
    navigate("/");
   };
 
  return (
    <PopupContainer>
      <ConfirmationCard>
        <HeaderWrapper>
          <CloseButton
            src={white_close}
            alt="Close button"
            onClick={handlethankClose}
          />
          <LogoContainer>
            <MainLogo loading="lazy" src={after_set_default_card} alt="Main logo" />
          </LogoContainer>
        </HeaderWrapper>

        <Message>
        Your default card has been set successfully. You<br></br> can now upgrade your subscription
        
        </Message>
    
       
       
      </ConfirmationCard>
    </PopupContainer>
  );
}

const PopupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Optional: for a dark overlay effect */
  z-index: 9999; /* Ensure it appears on top of other content */
`;

const BillingDetails = styled.section`
  letter-spacing: 0.18px;
  text-align: center;
  color: #121212;
  margin-top: 10px;
  font: 500 14px/20px Poppins, sans-serif;
`;

const EmailStyled = styled.span`
 
  font: 700 14px/20px Poppins, sans-serif;
`;

const Message = styled.p`
  letter-spacing: 0.18px;
  text-align: center;
  color: #121212;
  margin-top: 20px;
  font: 700 14px/22px Poppins, sans-serif;
`;

const HeaderWrapper = styled.header`
  background-color: #9DD9BC;
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 12px;
`;

const CloseButton = styled.img`
  position: absolute;
  top: 15px;
  right: 19px;
  width: 34px; /* Adjust size as needed */
  height: 34px; /* Adjust size as needed */
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MainLogo = styled.img`
  width: 100%;
  max-width: 440px;
  
  height: auto;
  
  margin-top: 12px;
`;

const ConfirmationCard = styled.div`
  border-radius: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  max-width: 440px;
  height: 370px;
  width: 100%;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  @media (max-width: 991px) {
    
    margin-right: 20px;
    margin-left:20px;
    
  }
`;

export default After_set_cards;
